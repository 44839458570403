import statusService from "./statusService";

const statusConfig = {
  designStages: [
    { status: "Designs created in WorksManager", statusIcon: "S" },
    // { status: "Design sync jobs completed", statusIcon: "N" },
    { status: "Design Available in TCC", statusIcon: "N" },
    { status: "Device Sync completed", statusIcon: "N" },
  ],
  designStagesWithVCL: [
    { status: "Designs created in WorksManager", statusIcon: "S" },
    { status: "Design conversion jobs completed", statusIcon: "N" },
    // { status: "Design sync jobs completed", statusIcon: "N" },
    { status: "Design Available in TCC", statusIcon: "N" },
    { status: "Device Sync completed", statusIcon: "N" },
  ],
  functionsToInvokeVCL: [
    statusService.getConversionData,
    statusService.checkTCCAvailability,
    statusService.checkFirmware,
  ],
  functionsToInvoke: [
    statusService.checkTCCAvailability,
    statusService.checkFirmware,
  ],
};

export default statusConfig;
