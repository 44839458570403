export default class accountInfo {
    constructor(account = {}) {
        this.setAccount(account);
    }

    setAccount(account) {
        this.name = account.name;
        this.description = account.description;
        this.tid = account.id;
        this.createdBy = account.createdBy ? account.createdBy : undefined;
        this.updatedBy = account.updatedBy;
        this.createdTimeStamp = account.createdTimeStamp;
        this.updatedTimeStamp = account.updatedTimeStamp;
        this.lifeState = account.lifeState ? account.lifeState : undefined;
    }
}
