import React from "react";

import "@trimbleinc/modus-bootstrap/dist/modus.min.css";
import { observer } from "mobx-react";
import { useEffect } from "react";
import Header from "../../components/Header/Header";
import SideNav from "../../components/SideNav/SideNav";
import Router from "../../router";
import { setUserContext } from "../../shared/analytics/analytics";
import axiosInterceptor from "../../shared/axiosinterceptor";
import FeatureManagementStore from "../../shared/featuremanagement/featureManagementStore";
import UserService from "../privatepage/userService";
import UserStore from "../privatepage/userStore";
import "./styles.scss";

const Home = observer((props) => {
  const featureManagementStore = FeatureManagementStore;

  useEffect(() => {
    const userService = new UserService();
    const userStore = new UserStore(userService);
    axiosInterceptor.setInterceptor();
    const userStoreInit = async () => {
      await userStore.init();
      featureManagementStore.setUserContext(userStore.me);
      setUserContext({
        email: userStore.me.email,
      });
    };
    try {
      userStoreInit();
    } catch (e) {
      console.error(e);
    }
  }, [featureManagementStore]);

  return (
    <div className="App">
      <Header />
      <div className="flex-container">
        <div className="child-div sideNav">
          <SideNav features={featureManagementStore} />
        </div>
        <div className="child-div mainApp">
          <Router features={featureManagementStore.features} />
        </div>
      </div>
    </div>
  );
});
export default Home;
