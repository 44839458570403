import React, { useMemo } from 'react';
import widgetConfig from './widgetConfig';

const Widget = ({ customWidgetConfig, className, title }) => {
  const baseURL = 'https://trimble-ccs.status.page/widget';
  const url = useMemo(() => {
    const searchParamsObj = {
      ...widgetConfig,
      ...customWidgetConfig,
    };
    const url = new URL(baseURL);
    Object.entries(searchParamsObj).forEach(([key, value]) =>
      url.searchParams.append(key, value)
    );
    return url;
  }, [customWidgetConfig]);

  const attributes = useMemo(() => {
    return {
      className,
      style: { border: 0 },
      src: url.href,
    };
  }, [className, url]);

  return (
    <div id={`${title}-widget`}>
      <iframe {...attributes} title={title} />
    </div>
  );
};

export default Widget;
