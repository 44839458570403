import { Button, Form, Modal } from "@trimbleinc/modus-react-bootstrap";
import React, { useEffect, useState } from "react";

/**
 *
 * This is a reusable form. Currently I am statically setting the fields, but this could easily be provided via props
 * Each field provided will create a textbox, and will also be added to the state, as a single object. E.g. {tccname: "", projecname:""}
 * You can set a field as not required, in which case we will skip checking if its blank.
 */

const ProvisionModal = (props) => {
  //We could easily change this to be provided via props. ID MUST BE UNIQUE
  const propFields = [
    {
      title: "Short Org Name or Account name",
      id: "orgName",
      required: true,
    },
    // {
    //   title: "Account Name",
    //   id: "accName",
    //   required: false,
    // },
  ];

  const [fieldVals, setFieldVals] = useState({});
  const [valid, isValid] = useState(false);

  //On mount create empty object of all the keys we have been via provided via props, and set it in the state as an object.
  //This in turn causes the submit to be disabled, until they have data. (as fieldVals trims them and the result is  isvalid(false))
  useEffect(() => {
    const keys = propFields.reduce((all, curr) => {
      all[curr.id] = "";
      return all;
    }, {});
    setFieldVals(keys);
  }, []);

  //Loop over the fields in state and ensure they have data. Unless not required.
  //Set it to true when all fields have data
  useEffect(() => {
    for (const field in fieldVals) {
      let required = propFields.find((x) => x.id === field).required;
      //Check if required. If so, does trimming it makes it empty.
      if (required && fieldVals[field].trim() === "") {
        isValid(false); // empty and thus invalid.
        return;
      }
    }
    isValid(true);
  }, [fieldVals]);

  //Update the state with the new value of the textbox.
  const handleFieldChange = (e) => {
    const tmp = { [e.target.id]: e.target.value };
    setFieldVals({ ...fieldVals, ...tmp });
  };

  const handleSubmit = () => {
    props.setData(fieldVals);
    props.handleClose();
  };

  const onSub = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Modal show={props.show} centered>
        <Modal.Header>
          <Modal.Title>Details</Modal.Title>
          <i
            className="modus-icons"
            style={{ cursor: "pointer" }}
            onClick={() => props.handleClose()}
          >
            close
          </i>
        </Modal.Header>
        <Form onSubmit={onSub}>
          <Modal.Body>
            {/* Loop over the propFields array and construct a form. */}
            {propFields.map((field) => {
              return (
                <Form.Group controlId={field.id} key={field.id}>
                  <Form.Label>{field.title}</Form.Label>
                  <Form.Control
                    as="input"
                    placeholder={field.title}
                    required={propFields?.required}
                    onChange={handleFieldChange}
                    autoComplete="off"
                  ></Form.Control>
                </Form.Group>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit} disabled={!valid}>
              {/* type="submit" */}
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ProvisionModal;
