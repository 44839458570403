export const accountNameChangeConfig =({shortOrgName, oldAccountName}) =>  [
  {
    title: "Short Org Name",
    id: "shortOrgName",
    required: false,
    value: shortOrgName,
    disabled: true,
  },
  {
    title: "Old Account Name",
    id: "oldinput",
    required: false,
    value: oldAccountName,
    disabled: true,
  },
  {
    title: "New Account Name",
    id: "input",
    required: true,
    disabled: false,
  },
  {
    title: "Confirm New Account Name",
    id: "confirmInput",
    required: true,
    disabled: false,
  },
];
export const shortOrgChangeConfig =({shortOrgName}) =>  [
  {
    title: "Old Short Org Name",
    id: "oldinput",
    required: false,
    value: shortOrgName,
    disabled: true,
  },
  {
    title: "New Short Org Name",
    id: "input",
    required: true,
    disabled: false,
  },
  {
    title: "Confirm New Short Org Name",
    id: "confirmInput",
    required: true,
    disabled: false,
  },
];
export const accountNameChangeCheck = (fieldVals, propData) => {
  for (const field in fieldVals) {
    let required = propData.find((x) => x.id === field)?.required;
    if (required && fieldVals[field].trim() === "") {
      return false
    }
    if(field?.startsWith('confirmInput')){
      const inputId = field.replace('confirmInput', 'input');
      const oldinputId = field.replace('confirmInput', 'oldinput');
      const oldValue = propData.find((x) => x.id === oldinputId).value;
      if (fieldVals[inputId] !== fieldVals[field] || (oldValue && fieldVals[inputId] === oldValue)) {
          return false;
    }
    }
  }
  return true;
}