export const featureMapping = [
  {
    name: "designToDevice",
    cloudbeesFlag: "Design To Device",
  },
  {
    name: "provisioning",
    cloudbeesFlag: "Provisioning",
  },
  {
    name: "remoteAssist",
    cloudbeesFlag: "Remote Assist",
  },
  {
    name: 'changeShortOrg',
    cloudbeesFlag: "Update Short Org Name"
  }
];
