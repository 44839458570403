import React, { useEffect, version } from "react";
import { useState } from "react";
import CCard from "../../../shared/Card/Card";
import { Row, Col, Form, Container } from "@trimbleinc/modus-react-bootstrap";
import "./details.scss";
import DesignService from "../AWS/DesignService";
import { Spinner } from "@trimbleinc/modus-react-bootstrap";

const service = DesignService;
let selectedVersion = null;
let selectedAccount = null;

const Details = (props) => {
  const [accountLoading, setAccountLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [designLoading, setDesignLoading] = useState(false);
  const [designVersionLoading, setDesignVersionLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [udesign, setDesign] = useState(null);
  const [udevice, setDevice] = useState(null);
  const { accounts, projects, designs, devices } = props.data;

  useEffect(() => {
    const getData = async () => {
      setAccountLoading(true);
      await service.getProject(
        props.data.accounts[0].id,
        props.data,
        props.setData,
        props.data.projectName || ""
      );
      setAccountLoading(false);
    };
    if (props.data.accounts && props.data.accounts.length === 1) {
      getData();
      selectedAccount = props.data.accounts[0].id;
    }
  }, [props.data.accounts]);

  useEffect(() => {
    setDesign(null);
    const getData = async () => {
      setProjectLoading(true);
      setSelectedProject(props.data.projects[0]);
      await service.getDesigns(
        props.data.projects[0].id,
        props.data,
        props.setData
      );
      setProjectLoading(false);
    };
    if (props.data.projects && props.data.projects.length === 1) {
      getData();
    }
  }, [props.data.projects]);

  useEffect(() => {
    setDesign(null);
    const getData = async () => {
      setDesignLoading(true);
      setDesign(props.data.designs[0]);
      selectedVersion = parseInt(props.data.designs[0].versions[0].version);
      props.setData({
        ...props.data,
        devices: null,
      });
      await service.getDevices(
        selectedProject.id,
        props.data.designs[0],
        selectedVersion,
        props.data,
        props.setData
      );
      setDesignLoading(false);
    };
    if (props.data.designs && props.data.designs.length === 1) {
      getData();
    }
  }, [props.data.designs]);

  useEffect(() => {
    if (props.data.devices && props.data.devices.length === 1) {
      setDevice(props.data.devices[0]);
    }
  }, [props.data.devices]);

  const handleAccount = async (event) => {
    props.setData({
      ...props.data,
      projects: null,
      designs: null,
      devices: null,
    });
    setDevice(null);
    setDesign(null);
    if (event.target.value === "Select") {
      return;
    }
    setAccountLoading(true);
    await service.getProject(
      event.target.value,
      props.data,
      props.setData,
      props.data.projectName || ""
    );
    selectedAccount = event.target.value;
    setAccountLoading(false);
    props.setStatus(false);
  };
  const handleProject = async (event) => {
    props.setData({
      ...props.data,
      designs: null,
      devices: null,
    });
    setDesign(null);
    setDevice(null);
    if (event.target.value === "Select") {
      return;
    }
    setProjectLoading(true);
    setSelectedProject(props.data.projects[event.target.value]);
    await service.getDesigns(
      props.data.projects[event.target.value].id,
      props.data,
      props.setData
    );
    setProjectLoading(false);
    props.setStatus(false);
  };
  const handleDesign = async (event) => {
    props.setData({
      ...props.data,
      devices: null,
    });
    setDevice(null);
    setDesign(null);
    if (event.target.value === "Select") {
      return;
    }
    setDesignLoading(true);
    setDesign(props.data.designs[event.target.value]);
    selectedVersion = parseInt(
      props.data.designs[event.target.value].versions[0].version
    );

    await service.getDevices(
      selectedProject.id,
      props.data.designs[event.target.value],
      selectedVersion,
      props.data,
      props.setData
    );
    setDesignLoading(false);
    props.setStatus(false);
  };

  const handleDesignVersion = async (event) => {
    props.setData({
      ...props.data,
      devices: null,
    });
    setDevice(null);
    selectedVersion = null;
    if (event.target.value === "Select") {
      return;
    }
    selectedVersion = event.target.value;
    setDesignVersionLoading(true);
    await service.getDevices(
      selectedProject.id,
      udesign,
      selectedVersion,
      props.data,
      props.setData
    );
    setDesignVersionLoading(false);
    props.setStatus(false);
  };

  const handleDevice = (event) => {
    setDevice(null);
    if (event.target.value === "Select") {
      return;
    }
    setDevice(props.data.devices[event.target.value]);
    props.setStatus(false);
  };
  const handleStatus = () => {
    let versionIndex = udesign.versions.findIndex(
      (versionOfDesign) => versionOfDesign.version == selectedVersion
    );
    props.setData((prevData) => ({
      ...prevData,
      statusDetails: {
        isVCLDesign:
          udesign.versions[versionIndex].convertedFile1Name &&
          udesign.versions[versionIndex].convertedFile1Name.indexOf(".vcl") > 0,
        selectedAccount,
        selectedProject,
        selectedDesign: udesign,
        selectedVersion,
        selectedDevice: udevice,
      },
    }));
    props.setStatus(true);
  };

  const checkLoading = () => {
    return (
      accountLoading || projectLoading || designLoading || designVersionLoading
    );
  };

  return (
    <div>
      <h1>Design to Device</h1>
      <CCard
        title={"Details"}
        onEdit={props.onEdit}
        isEdit={true}
        buttonAction={handleStatus}
        buttonTitle="Check Status"
        buttonDisabled={
          checkLoading() || !accounts || !projects || !udesign || !udevice
        }
      >
        <Form style={{ paddingTop: "10px" }}>
          <Container style={{ paddingTop: "18px" }}>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  <h5 className="details">Account Name</h5>
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    as="select"
                    onChange={handleAccount}
                    disabled={checkLoading()}
                  >
                    {accounts ? (
                      <>
                        {accounts.length > 1 && (
                          <option value={"Select"}>Select an account</option>
                        )}
                        {accounts.map((account, idx) => (
                          <option value={account.id} key={idx}>
                            {account.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>No Account Found</option>
                    )}
                  </Form.Control>
                </Col>
                <Col sm={1}>
                  {accountLoading && (
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </Col>
                <Form.Label column sm={2}>
                  <h5 className="details">Project Name</h5>
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    as="select"
                    onChange={handleProject}
                    disabled={checkLoading()}
                  >
                    {projects ? (
                      <>
                        {projects.length > 1 && (
                          <option value={"Select"}>Select a Project</option>
                        )}
                        {projects.map((project, idx) => (
                          <option value={idx} key={idx}>
                            {project.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>No Project Found</option>
                    )}
                  </Form.Control>
                </Col>
                <Col sm={1}>
                  {projectLoading && (
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </Col>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  <h5 className="details">Design Name</h5>
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    as="select"
                    onChange={handleDesign}
                    disabled={checkLoading()}
                  >
                    {designs ? (
                      <>
                        {designs.length > 1 && (
                          <option value={"Select"}>Select a design</option>
                        )}
                        {designs.map((design, idx) => (
                          <option key={idx} value={idx}>
                            {design.designName}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>No Designs found</option>
                    )}
                  </Form.Control>
                </Col>
                <Col sm={1}>
                  {designLoading && (
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </Col>
                <Form.Label column sm={2}>
                  <h5 className="details">Design Version</h5>
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    as="select"
                    onChange={handleDesignVersion}
                    disabled={checkLoading()}
                  >
                    {udesign && udesign.versions ? (
                      <>
                        {udesign.versions.length > 1 && (
                          <option value={"Select"}>
                            Select a design version
                          </option>
                        )}
                        {udesign.versions.map((version, idx) => (
                          <option
                            key={idx}
                            value={version.version}
                            selected={selectedVersion === version.version}
                          >
                            {version.version}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>Please Select a design</option>
                    )}
                  </Form.Control>
                </Col>
                <Col sm={1}>
                  {designVersionLoading && (
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  <h5 className="details">Published Devices</h5>
                </Form.Label>
                <Col sm={3}>
                  <Form.Control
                    as="select"
                    onChange={handleDevice}
                    disabled={checkLoading()}
                  >
                    {devices ? (
                      <>
                        {devices.length > 1 && (
                          <option value={"Select"}>Select a device</option>
                        )}
                        {devices.map((device, idx) => (
                          <option key={idx} value={idx}>
                            {device.deviceType}-{device.serialNumber}
                          </option>
                        ))}
                      </>
                    ) : (
                      <option>No Devices found</option>
                    )}
                  </Form.Control>
                </Col>
                <Col sm={1}></Col>
                <Col sm={2}></Col>
              </Form.Group>
            </Col>
          </Container>
        </Form>
      </CCard>
    </div>
  );
};

export default Details;
