import axios from "axios";
import qs from "query-string";
const configuration = window.appdata;

/**
 *  Export functions rather than classes.
 */

export async function changeTccLicenseDetails(
  details,
  newDeviceCountLimit,
  cancelToken
) {
  //Take the existing details, and supplement the new license count into it.
  //Take care when changing, as some properties utilise caps, and others dont. This is tcc behaviour.
  const request_body = {
    tcc: {
      orgId: details.orgId,
      description: details.description,
      guestsCanLogin: details.guestsCanLogin,
      guestRegistrationEnabled: details.guestRegistrationEnabled,
      deviceCountLimit: newDeviceCountLimit, //the main feild to update.
      visualizationlicensecount: details.visualizationlicensecount,
      orgAdminsCanLogin: details.orgAdminsCanLogin,
      orgtype: details.orgtype,
      shortname: details.shortname,
      title: details.title,
      usersCanLogin: details.usersCanLogin,
      maxsyncfolders: details.maxsyncfolders,
      serveridnumber: details.serveridnumber,
      expirationdate: details.expirationdate,
      orglicensetype: details.orglicensetype,
      guestlicensetype: details.guestlicensetype,
      licensingandbillingemail: details.licensingandbillingemail,
      systemandserviceemail: details.systemandserviceemail,
    },
    // oldlic : details.deviceCountLimit
  };
  try {
    const response = await axios.post(
      `${configuration.api_url}${configuration.amazon_api_url}tcclicenseupdate`,
      request_body
    );
    return response;
  } catch (err) {
    return false;
  }
}

//TODO: WRAP IN TRY CATCH FOR 401 ERRORS
export async function searchAccountsShortOrgName(
  from,
  limit,
  shortOrgName,
  cancelToken
) {
  //If it has spaces, wrap in quotes.
  if (shortOrgName.indexOf(" ") >= 0) {
    shortOrgName = `'${shortOrgName}'`;
  }

  const querystring = qs.stringify({
    from,
    limit,
    accountText: shortOrgName,
  });
  let queryParam = "";
  if (querystring) {
    queryParam = `?${querystring}`;
  }

  let apiCall = `${configuration.api_url}${configuration.amazon_api_url}shortorgaccount${queryParam}`;
  const response = await axios.get(apiCall, cancelToken);
  return response;
}

export async function getTccLicenseDetails(profilexid) {
  const querystring = qs.stringify({
    profilexId: profilexid,
  });

  let queryParam = "";

  if (querystring) {
    queryParam = `?${querystring}`;
  }

  let apiCall = `${configuration.api_url}${configuration.amazon_api_url}tcclicensecheck${queryParam}`;
  const response = await axios.get(apiCall);

  return response.data;
}
//Account name change service
export async function changeAccountName(
  oldAccountName,
  tccOrgId,
  newAccountName
) {
  let body = {
    oldAccountName,
    tccOrgId,
    newAccountName,
  };
  let url = `${configuration.api_url}${configuration.design_to_device_service_url}accountNameChange`;
  try {
    let response = await axios.put(url, body);
    return response;
  } catch (error) {
    let clientRequest = JSON.parse(error.request.response);
    if (clientRequest.message) {
      return clientRequest;
    }
    return {
      message: "Account Name change failed",
      status: clientRequest.status || error.response.status,
    };
  }
}

export async function changeShortOrgName({
  accountId,
  oldShortOrgName,
  newShortOrgName
}) {
  let body = {
    oldShortOrgName,
    newShortOrgName
  };
  let url = `${configuration.api_url}${configuration.design_to_device_service_url}${accountId}/shortOrgChange`;
  try {
    let response = await axios.put(url, body);
    return response;
  } catch (error) {
    let clientRequest = JSON.parse(error.request.response);
    if (clientRequest.message) {
      return clientRequest;
    }
    return {
      message: "Short org change failed",
      status: clientRequest.status || error.response.status,
    };
  }
}

//Mock an api return
async function mockApiCall(result) {
  const wait = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  await wait(3000);
  return result;
}

export async function changeExpirationDate(details, newExpirationDate) {
  //Take the existing details, but replace expiration date.
  const request_body = {
    tcc: {
      orgId: details.orgId,
      description: details.description,
      guestsCanLogin: details.guestsCanLogin,
      guestRegistrationEnabled: details.guestRegistrationEnabled,
      deviceCountLimit: details.deviceCountLimit,
      visualizationlicensecount: details.visualizationlicensecount,
      orgAdminsCanLogin: details.orgAdminsCanLogin,
      orgtype: details.orgtype,
      shortname: details.shortname,
      title: details.title,
      usersCanLogin: details.usersCanLogin,
      maxsyncfolders: details.maxsyncfolders,
      serveridnumber: details.serveridnumber,
      expirationdate: newExpirationDate, //the main feild to update.
      orglicensetype: details.orglicensetype,
      guestlicensetype: details.guestlicensetype,
      licensingandbillingemail: details.licensingandbillingemail,
      systemandserviceemail: details.systemandserviceemail,
    }
  };
  try {
    const response = await axios.post(
      `${configuration.api_url}${configuration.amazon_api_url}tcctrialexpirationdateupdate`,
      request_body
    );
    return response;
  } catch (err) {
    return false;
  }
}
