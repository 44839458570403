import axios from "axios";
import deviceModels from "./deviceModels.json";

const configuration = window.appdata;

const siteCollectorDevices = deviceModels.MachineControlDevices.concat(
  deviceModels.SiteCollectorDevices
);
const machineControlDevices = deviceModels.MachineControlDevices.concat(
  deviceModels.MachineControlDevices
);
class DesignToDeviceService {
  async getAccount(modalData, setError, data, setData) {
    if (
      modalData.orgName === data.orgName &&
      modalData.projectName === data.projectName
    ) {
      setData({
        ...data,
        ...modalData,
      });
      return;
    }
    const querystring = {
      accountText: `"${modalData.orgName}"`,
    };
    const url = `${configuration.api_url}${configuration.amazon_api_url}shortorgaccount`;
    try {
      const response = await axios.get(url, {
        params: {
          ...querystring,
        },
      });
      if (response.data.length === 0) {
        setError(true);
      } else {
        setError(false);
        setData({
          ...modalData,
          accounts: response.data.sort((account1, account2) =>
            account1.name.toLowerCase() > account2.name.toLowerCase()
              ? 1
              : account2.name.toLowerCase() > account1.name.toLowerCase()
              ? -1
              : 0
          ),
        });
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }
  async getProject(
    accountID,
    modalData,
    setData,
    projectName = "",
    from = 0,
    limit = 100
  ) {
    const querystring = {
      from,
      limit,
      accountID,
      projectName,
    };
    const url = `${configuration.api_url}${configuration.amazon_api_url}accountproject`;
    try {
      const response = await axios.get(url, {
        params: {
          ...querystring,
        },
      });
      if (response.data.length === 0) {
        setData({
          ...modalData,
          projects: null,
          devices: null,
          designs: null,
        });
      } else {
        setData({
          ...modalData,
          projects: response.data.sort((project1, project2) =>
            project1.name.toLowerCase() > project2.name.toLowerCase()
              ? 1
              : project2.name.toLowerCase() > project1.name.toLowerCase()
              ? -1
              : 0
          ),
          devices: null,
          designs: null,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getDesigns(projectID, modalData, setData, from = 0, limit = 100) {
    const querystring = {
      from,
      limit,
      projectID,
    };
    const url = `${configuration.api_url}${configuration.amazon_api_url}designlist`;
    try {
      const response = await axios.get(url, {
        params: {
          ...querystring,
        },
      });
      if (response.data.length !== 0) {
        let designs = response.data.sort((design1, design2) =>
        design1.designName.toLowerCase() > design2.designName.toLowerCase()
          ? 1
          : design2.designName.toLowerCase() >
            design1.designName.toLowerCase()
          ? -1
          : 0
      );
      for(let i = 0; i < designs.length; i++){
        designs[i].versions = designs[i].versions.filter(version => version.liveStatus === 'Y')
      }
        setData({
          ...modalData,
          designs,
          devices: null,
        });
      } else {
        setData({
          ...modalData,
          designs: null,
          devices: null,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getDevices(
    projectID,
    design,
    designVersion,
    modalData,
    setData,
    from = 0,
    limit = 100
  ) {
    let deviceTypes = [];
    design.machineTypes.forEach((element) => {
      if (element === "MACHINE_CONTROL") {
        deviceTypes += machineControlDevices;
      } else if (element === "SITE_COLLECTOR") {
        deviceTypes += siteCollectorDevices;
      }
    });
    const string1 = deviceTypes.toString();
    const querystring = {
      from,
      limit,
      projectID,
      deviceTypes: string1,
    };
    const url = `${configuration.api_url}${configuration.amazon_api_url}devicesofdesign`;
    try {
      const response = await axios.get(url, {
        params: {
          ...querystring,
        },
      });
      let devices = response.data;
      if (response.data.length !== 0) {
        designVersion = design.versions.findIndex(
          (versionOfDesign) => versionOfDesign.version === designVersion
        );
        if (
          design.versions[designVersion].devices != null &&
          design.versions[designVersion].devices.length !== 0
        ) {
          let devicespublished = [];
          for (
            let i = 0;
            i < design.versions[designVersion].devices.length;
            i++
          ) {
            devicespublished.push(design.versions[designVersion].devices[i].id);
          }
          devices = devices.filter((device) =>
            devicespublished.includes(device.deviceId)
          );
        }
      }
      devices = devices.length > 0 ? devices : null;
      setData({
        ...modalData,
        devices,
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export default new DesignToDeviceService();
