import { useLocation } from "react-router-dom";
import { PrivateRoute } from "../shared/privateroute";
import Home from "./home";

function App() { 
  const location = useLocation();
  return (
    <div className="App">
      <PrivateRoute location={location}>
        <Home />
      </PrivateRoute>
    </div>
  );
}
export default App;
