import axios, { Cancel } from 'axios';
import authenticator from './authenticator';
import AuthService from './authenticator/authservice';
import Cookies from 'universal-cookie';

class AxiosInterceptor {
    constructor() {
        this.accessTokenPending = false;
    }

    setInterceptor() {
        axios.interceptors.request.use(
            function (config) {
                const cookie = new Cookies();
                const token = cookie.get('access_token') || '';
                config.baseURL = config.baseURL || window.appdata.api_url;
                config.headers = Object.assign(
                    {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    config.headers
                );
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                let code = '';
                if (error.response) {
                    code = error.response.data.fault
                        ? error.response.data.fault.code
                        : error.response.data.code || '000000';
                }

                // Check to see if this error is due to token expiry (it is reported differently for Trimble Connect / Works manager apis)
                let isTokenExpiry = false;

                if (error.request?.responseURL?.indexOf(window.appdata.trimbleConnect_api) !== -1) {
                    // This error is from a Trimble Connect api
                    isTokenExpiry = error.response?.status === 401 && error.response?.data?.errorDetails?.details === "JWT_TOKEN_EXPIRED";
                } else {
                    // assume if not Trimble Connect, it is from a Works Manager api
                    isTokenExpiry = error.response?.status === 401 && code === "900903";
                }

                if (
                    !error.response ||
                    (!error.response.data.fault && !error.response.data.code && !isTokenExpiry)
                ) {
                    if (!(error instanceof Cancel)) {
                        error.response = {
                            status: 500,
                            data: {
                                code: '000000',
                            },
                        };
                    }
                } else {
                    if (error.response.status === 401) {
                        if (isTokenExpiry) {
                            return new Promise((resolve, reject) => {
                                // We need to request a new access token.
                                // However, we need to avoid concurrent calls to get a new access token otherwise the 2nd call will fail
                                // because its refresh_token will become stale as soon as the 1st request is actioned by the server.
                                // Therefore we are creating a custom event to broadcast the new token to (potentially) multiple listeners once the new token is received.
                                document.addEventListener(
                                    'newAccessToken',
                                    (event) => {
                                        error.config.headers['Authorization'] =
                                            'Bearer ' + event.detail.access_token;
                                        resolve(axios.request(error.config));
                                    },
                                    {
                                        once: true
                                    }
                                );

                                if (!this.accessTokenPending) {
                                    this.accessTokenPending = true;
                                    const authService = new AuthService();
                                    return authService
                                        .getTokens()
                                        .then((response) => {
                                            this.accessTokenPending = false;
                                            const event = new CustomEvent('newAccessToken', { detail: response.data });
                                            // notify all listeners with the new token
                                            document.dispatchEvent(event);
                                        });
                                }
                            });
                        } else {
                            authenticator.authenticate();
                        }
                    }
                }
                return Promise.reject(error);
            }
        );
    }
}

export default new AxiosInterceptor();
