import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import "./provision.scss";
import imgEdit from "../../assets/edit.svg";
import {
  searchAccountsShortOrgName,
  getTccLicenseDetails,
  changeTccLicenseDetails,
  changeAccountName,
  changeExpirationDate,
  changeShortOrgName
} from "./provisionService";
import FormModal from "./FormModal";
import ExpirationDateChangeModal from "./ExpirationDateChangeModal";
import {
  Alert,
  AlertDismissible,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from '@trimbleinc/modus-react-bootstrap';
import moment from 'moment';
import { accountNameChangeCheck, accountNameChangeConfig, shortOrgChangeConfig } from "./ProvisionModalConfig";
import { observer } from "mobx-react";

const Provision = (props) => {
  const noAccwarningMessage =
    'No account was found with this shortname. Please try again.';
  const errorMessage =
    'Oops, something went wrong! Please refresh and try again.';
  const succMessage = 'License count successfully updated.';
  const licensetooltip =
    'Please ensure that the new license count is greater than the existing one, and that you are not trying to add more than 15 licenses.';

  //The state used to track initialisation, api errors
  const [initialising, setInitialising] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const [allowShortOrgChange, setAllowShortOrgChange] = useState(false);
  //States used to track scenarios where we need user interaction. e.g. select an account, no acc found.
  const [noAccFound, setNoAccFound] = useState(false);
  const [chooseAcc, setChooseAcc] = useState([]);

  //The object that contains our UI details
  const [provisionDetails, setprovisionDetails] = useState({});
  //The object that contains our API responses.
  const [apiPayload, setApiPayload] = useState({});

  //The new license count input by the user, and if it is valid.
  const [isValidLicenseCount, setIsValidLicenseCount] = useState(false);
  const [newLicenseCount, setNewLicenseCount] = useState(0);

  //The confirmation popup, and the value where the user double enters new license count.
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmValue, setConfirmValue] = useState(false);

  const [isShortOrg, setIsShortOrg] = useState(false);

  const [showAccountName, setShowAccountName] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const [isAccountNameChangeLoading, setIsAccountNameChangeLoading] =
    useState(false);
  const [nameChange, setNameChange] = useState(false);
  const [nameChangeError, setNameChangeError] = useState(false);
  const [changeAccName, setChangeAccName] = useState(true);

  const [showExpirationDateChangeModal, setShowExpirationDateChangeModal] =
    useState(false);
  const [expirationDateChangeSuccess, setExpirationDateChangeSuccess] =
    useState(false);
  const [expirationDateChangeError, setExpirationDateChangeError] =
    useState(false);
  const [isExpirationDateChangeLoading, setIsExpirationDateChangeLoading] =
    useState(false);

    const fetchLicenseDetails = useCallback(async(acc)=> {
      setInitialising(true);
      //We could move this to a reset function?
      setNewLicenseCount('');
      setApiPayload();
      // setprovisionDetails({});
  
      //TODO: TRY CATCH WRAP THIS SO WE CAN SET setApiError
      let details = await getTccLicenseDetails(acc.id);
  
      //Also store the payload. This will be used once we submit a license change.
      setApiPayload(details);
  
      let expirationDate = moment(
        String(details.expirationdate),
        'YYYY-MM-DD HH:mm:ss.SSS',
        true
      ).format('YYYY-MM-DD');
      //set details to be rendered onscreen.
      setprovisionDetails({
        shortOrgName: details.shortname,
        tccTitle: details.title,
        accountName: acc.name || accountDetails.name,
        profilexid: acc.id,
        tccLicenseCount: details.deviceCountLimit,
        licenseType: details.orglicensetype,
        deviceLicenseUsed: details.devicelicenseused,
        licenseExpirationDate: expirationDate,
      });
  
      //TODO: this sets to false before setProvisionDetails has updated the state, fix this condition properly!
      setInitialising(false);  
    },[accountDetails.name])

  const init = useCallback(async(name) => {
      setInitialising(true);
      setChooseAcc([]);
      setNoAccFound(false);
      //We could move this to a reset function?
      setNewLicenseCount('');
      setApiPayload();
      setprovisionDetails({});
  
      //Fetch the accounts based on the shortname the user input. If more than one acc is returned present a selection, otherwise just load details.
      const tccshortname = name;
  
      //TODO: TRY CATCH WRAP THIS SO WE CAN SET setApiError
      const account = await searchAccountsShortOrgName(0, 25, tccshortname, null);
  
      if (!account.data.length) {
        //No accounts found.
        setNoAccFound(true);
        setInitialising(false);
      } else if (account.data.length > 1) {
        //Multiple accounts found, show selection dropdown ternery.
        //We show a dropdon when chooseAcc has a length. Once a selection is made, function selectAccountDrop() executes fetchlicensedetails().
        setChooseAcc(account.data);
        setInitialising(false);
      } else {
        //Single account found, display its info
        setAccountDetails(account.data[0]);
        fetchLicenseDetails(account.data[0]);
        setInitialising(false);
      }
    },[fetchLicenseDetails]  
   )

  useEffect(() => {
    init(props.data.orgName);
    setAllowShortOrgChange(props.features.changeShortOrg);
  }, [props.data.orgName, props.features, init]);

  //Perform validation on the new license count.
  useEffect(() => {
    //Disallow reduction of liceneses
    if (newLicenseCount <= provisionDetails?.tccLicenseCount) {
      setIsValidLicenseCount(false);
    }
    //invalid license
    else if (
      isNaN(newLicenseCount) ||
      newLicenseCount === 0 ||
      newLicenseCount === ''
    ) {
      setIsValidLicenseCount(false);
    }
    //increasing by too much
    else if (newLicenseCount - provisionDetails?.tccLicenseCount > 15) {
      setIsValidLicenseCount(false);
    }
    //valid
    else {
      setIsValidLicenseCount(true);
    }
  }, [newLicenseCount,provisionDetails?.tccLicenseCount]);

  
  

  async function handleAccountNameChange(data) {
    setIsAccountNameChangeLoading(true);
    const accDetails = {
      ...data,
      shortOrgName: provisionDetails.shortOrgName,
      oldAccountName: provisionDetails.accountName,
    };
    setAccountDetails(accDetails);
    let response = await changeAccountName(
      accDetails.oldAccountName,
      apiPayload.orgId,
      accDetails.input
    );
    if (response.status === 200) {
      setNameChange(true);
      setprovisionDetails({
        ...provisionDetails,
        accountName: accDetails.input,
      });
    } else {
      setNameChangeError(true);
    }
    setIsAccountNameChangeLoading(false);
  }

  async function handleShortOrg(data) {
    setIsAccountNameChangeLoading(true);
    const shortOrgDetails = {
        newShortOrgName : data.input,
        oldShortOrgName : provisionDetails.shortOrgName,
        accountId : provisionDetails.profilexid,
      }
    let response = await changeShortOrgName(
      shortOrgDetails
    );
    if (response.status === 204) {
      setNameChange(true);
      setprovisionDetails({
        ...provisionDetails,
        shortOrgName: data.input,
      });
    } else {
      setNameChangeError(true);
    }
    setIsAccountNameChangeLoading(false);
  }
  
 
  

  function innerCol(labelColSize, defaultLabel, valueColSize, value = '') {
    //Set our value only once initialisation is finished, and value is not NULL.
    const val = initialising ? '-' : value;

    return (
      <>
        <Form.Label column sm={labelColSize}>
          <h5>{defaultLabel}</h5>
        </Form.Label>

        <Col sm={valueColSize}>
          <Form.Control plaintext readOnly value={val} />
        </Col>
      </>
    );
  }

  function selectAccountDrop() {
    //The user has selected from a list of multiple accounts. begin api calls.
    const onAccClick = (account) => {
      setChooseAcc([]);
      setAccountDetails(account);
      fetchLicenseDetails(account);
    };
    return (
      <DropdownButton
        variant="primary"
        id="dropdown-basic-button"
        title="Please select an account"
        className="ml-3"
      >
        {chooseAcc.map((acc) => {
          return (
            <Dropdown.Item key={acc.id} onClick={() => onAccClick(acc)}>
              {acc.name}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>
    );
  }

  function messageBanner(variant, icon, text, dismissable) {
    //not working with ternery...
    if (dismissable) {
      return (
        <div>
          <AlertDismissible key="a5" variant={variant}>
            <i className="material-icons alert-icon">{icon}</i>
            {text}
          </AlertDismissible>
        </div>
      );
    }
    return (
      <div>
        <Alert key="a5" variant={variant}>
          <i className="material-icons alert-icon">{icon}</i>
          {text}
        </Alert>
      </div>
    );
  }
  //Used to perform confirmation of license count. Makes the user re-enter the value.
  function showConfirmationModal() {
    const submit = async () => {
      setShowConfirmation(false);
      setInitialising(true);
      const result = await changeTccLicenseDetails(apiPayload, newLicenseCount);
      if (result) {
        //show some kind of happy message
        setApiSuccess(true);
      } else {
        setApiError(true);
      }

      //After updating the count, reload the project details. We don't need to show a shortorg selection so we skip straight to fetchDetails and pass
      //in the existing details.
      const curAccId = {
        id: provisionDetails.profilexid,
      };
      //Reset these vars.
      setprovisionDetails({});
      setApiPayload();
      fetchLicenseDetails(curAccId);
    };

    const checkValid = (e) => {
      //Make the user re-enter the amount as a verification against typos.
      if (e.target.value === newLicenseCount) {
        setConfirmValue(true);
      } else {
        setConfirmValue(false);
      }
    };

    return (
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm license count change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to set the license count to <b>{newLicenseCount}</b>.
          Please confirm this change with the store team before proceeding.
          <br></br>
          <br></br>
          If you wish to continue please re-enter the new license count below:
          <Form.Control
            as="input"
            type="number"
            placeholder="Confirm license count"
            onChange={checkValid}
            valueasnumber={'true'}
            disabled={initialising}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" disabled={!confirmValue} onClick={submit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  function NewAccountNameChange(isShortOrg = false) {
    setShowAccountName(true);
    setIsShortOrg(isShortOrg);
  }


  function expirationDateChange() {
    setShowExpirationDateChangeModal(true);
  }

  useEffect(() => {
    if (provisionDetails.accountName && !initialising) {
      setChangeAccName(true);
    } else {
      setChangeAccName(false);
    }
  }, [provisionDetails, initialising]);
  //Currently the page is setup as a form? I don't think that is true, and will need to be changed.
  return (
    <>
      {/* LICENSE COUNT UPDATED OK */}
      {apiSuccess
        ? messageBanner('success', 'check_circle', succMessage, true)
        : ''}
      {/* API ERROR HAS OCCURED. DISPLAY WARNING */}
      {apiError ? messageBanner('danger', 'error', errorMessage, false) : ''}
      {/* NO ACCOUNT FOUND. ADVISE USED TO CHOOSE AGAIN */}
      {noAccFound
        ? messageBanner('warning', 'warning', noAccwarningMessage, false)
        : ''}
      {nameChange
        ? messageBanner(
            'success',
            'check_circle',
            'Account name changed successfully',
            true
          )
        : ''}
      {nameChangeError
        ? messageBanner("danger", "error", `${isShortOrg ? 'Short org' : 'Account name'} change failed`, true)
        : ""}
      {expirationDateChangeSuccess
        ? messageBanner(
            'success',
            'check_circle',
            'Trial expiration date changed successfully',
            true
          )
        : ''}
      {expirationDateChangeError
        ? messageBanner(
            'danger',
            'error',
            'Trial expiration date change failed',
            true
      )
      : ""}
      <FormModal
        modalTitle={"Account Details"}
        buttonText={"Change"}
        config={isShortOrg?shortOrgChangeConfig :accountNameChangeConfig}
        checkValid={accountNameChangeCheck}
        showAccountName={showAccountName}
        setIsAccountNameChangeLoading={setIsAccountNameChangeLoading}
        data={{
          shortOrgName: provisionDetails.shortOrgName,
          oldAccountName: provisionDetails.accountName,
        }}
        setData={isShortOrg?handleShortOrg:handleAccountNameChange}
        handleClose={() => {
          setShowAccountName(false);
        }}
      />
      
      <ExpirationDateChangeModal
        showModal={showExpirationDateChangeModal}
        oldExpirationDate={provisionDetails.licenseExpirationDate}
        changeDateAndCheckResponse={async (data) => {
          setIsExpirationDateChangeLoading(true);

          let newDate = moment(
            String(data.newExpirationDate),
            'YYYY-MM-DD',
            true
          ).format('YYYY-MM-DD HH:mm:ss.SSS');

          //make request to change trial expiration date
          let response = await changeExpirationDate(apiPayload, newDate);

          if (response && response.status === 200) {
            setExpirationDateChangeSuccess(true);
          } else {
            setExpirationDateChangeError(true);
          }

          //After updating the count, reload the project details.
          const curAccId = {
            id: provisionDetails.profilexid,
          };
          //Reset these vars.
          setprovisionDetails({});
          setApiPayload();
          fetchLicenseDetails(curAccId);

          setIsExpirationDateChangeLoading(false);
        }}
        handleClose={() => {
          setShowExpirationDateChangeModal(false);
        }}
      ></ExpirationDateChangeModal>

      <div className="provisioning">
        <h1></h1>
        <Card as="div" className="prov-card">
          <Card.Body className="card-body">
            <div className="flex">
              <div>WorksManager account details</div>
              <div>
                {initialising ? (
                  <Spinner animation="border" role="status" variant="primary">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  //props.onEdit will open the modal again (callback boolean). this allows you to enter a new account name which will then trigger
                  //props.data.orgName effect, reloading the page.
                  <span
                    className="clickable"
                    style={{ cursor: 'pointer' }}
                    onClick={props.onEdit}
                  >
                    <img src={imgEdit} title="Edit" alt="Edit" />
                  </span>
                )}
              </div>
            </div>
            <Form className="form">
              <Container>
                {/* MULTIPLE ACCOUNTS FOUND. MAKE A USER SELECT AN ACCOUNT */}
                {chooseAcc.length ? selectAccountDrop() : ''}
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      <h5>{'Account title'}</h5>
                    </Form.Label>
                    <Col sm={3}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={
                          provisionDetails ? provisionDetails.accountName : "-"
                        }
                      />
                    </Col>
                    <Col sm={1}>
                      {changeAccName &&
                        (!isShortOrg && isAccountNameChangeLoading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          >
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          <div
                            className="change-detail"
                            onClick={() => NewAccountNameChange(false)}
                          >
                            <img src={imgEdit} title="Change Account Name" alt="Change Account Name" />
                          </div>
                        ))}
                    </Col>
                    {innerCol(
                      2,
                      "Short Org Name:",
                      3,
                      provisionDetails?.shortOrgName
                    )}
                    {allowShortOrgChange && changeAccName &&
                        (isShortOrg && isAccountNameChangeLoading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            variant="primary"
                          >
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        ) : (
                          <div
                            className="change-detail"
                            onClick={() => NewAccountNameChange(true)}
                          >
                            <img src={imgEdit} title="Change Short Org Name" alt="Change Short Org Name" />
                          </div>
                        ))}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    {innerCol(
                      2,
                      'License Type',
                      4,
                      provisionDetails?.licenseType
                    )}
                    {innerCol(
                      3,
                      'Licenses used:',
                      3,
                      provisionDetails?.deviceLicenseUsed
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    {innerCol(
                      2,
                      'Available licenses:',
                      4,
                      provisionDetails?.tccLicenseCount
                    )}
                    {/* {innerCol(3, 'WM Licenses:', 3, provisionDetails?.wmLicenses)} */}
                    {
                      // only show expiration date if license type is trial
                      provisionDetails.licenseType?.toString() === 'trial' && (
                        <>
                          <Form.Label column sm={3}>
                            <h5>{'Trial Expiration Date:'}</h5>
                          </Form.Label>
                          <Col sm={2}>
                            <Form.Control
                              plaintext
                              readOnly
                              value={
                                provisionDetails
                                  ? provisionDetails.licenseExpirationDate
                                  : '-'
                              }
                            />
                            {isExpirationDateChangeLoading ? (
                              <Spinner
                                animation="border"
                                role="status"
                                variant="primary"
                              >
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <div
                                className="change-detail"
                                onClick={expirationDateChange}
                              >
                                Change Date
                              </div>
                            )}
                          </Col>
                        </>
                      )
                    }
                  </Form.Group>
                </Col>
                
                {/* Hiding this button as users should not be allowed to update the license through support tool */}
                {/* <div className="change-license">
                  <Form.Control
                    as="input"
                    type="number"
                    placeholder="Update Available Licenses"
                    // todo: fix this so that it clears when newLicenseCount changes
                    value={newLicenseCount}
                    onChange={(e) => setNewLicenseCount(e.target.value)}
                    valueasnumber={'true'}
                    disabled={initialising || isAccountNameChangeLoading}
                  />
                  <OverlayTrigger
                    key="overlay1"
                    placement="right"
                    show={
                      !isValidLicenseCount && !initialising && newLicenseCount
                        ? true
                        : false
                    }
                    overlay={
                      <Tooltip id="btntooltip">{licensetooltip}</Tooltip>
                    }
                  >
                    <Button
                      className="btn-primary"
                      href="#"
                      disabled={
                        !isValidLicenseCount || isAccountNameChangeLoading
                      }
                      onClick={() => setShowConfirmation(true)}
                    >
                      Submit
                    </Button>
                  </OverlayTrigger>
                </div> */}
              </Container>
            </Form>
            {showConfirmationModal()}
          </Card.Body>
        </Card>
        {/* {status && <Status />} */}
      </div>
    </>
  );
};

export default observer(Provision);
