import React, { Fragment } from "react";
import { Row, Col } from "@trimbleinc/modus-react-bootstrap";
import "./statusitem.scss";

const StatusItem = (props) => {
  const handleSelect = () => {
    if (!props.selected & props.isSelectable) props.setSelected(props.index);
  };

  return (
    <Fragment>
      <Row
        as="div"
        className={`m-0 ${props.isSelectable ? "card-clickable" : ""}`}
        onClick={handleSelect}
      >
        <Col sm={2}>
          <div className="circle">{props.icon}</div>
        </Col>
        <Col>
          <h5 className={`status${props.selected ? "-selected" : ""}`}>
            {props.status}
          </h5>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StatusItem;
