import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
} from "@trimbleinc/modus-react-bootstrap";
import DesignToDeviceService from "../AWS/DesignService";
let start = false;

const CModal = (props) => {
  const service = DesignToDeviceService;
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orgName, setOrgName] = useState(props.data.orgName || "");
  const [projectName, setProjectName] = useState(props.data.projectName || "");

  useEffect(() => {
    if (start && !loading && !error) {
      props.handleClose();
      start = false;
    }
  }, [props, loading, error]);

  useEffect(() => {
    if (orgName.trim() !== "") {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [orgName]);

  const handleOrgName = (event) => {
    setOrgName(event.target.value);
  };
  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };
  const handleApply = async () => {
    start = true;
    let data = {
      orgName,
      projectName,
    };
    setLoading(true);
    await service.getAccount(data, setError, props.data, props.setData);
    setLoading(false);
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Modal.Body>
            <Form.Group controlId="orgName">
              <Form.Label>TCC ShortOrg Name/Account Name *</Form.Label>
              <Form.Control
                as="input"
                placeholder="TCC Short Org Name"
                isInvalid={error}
                value={orgName || ""}
                onChange={handleOrgName}
                disabled={loading}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="Project Name">
              <Form.Label>Project Name</Form.Label>
              <Form.Control
                as="input"
                placeholder="Project Name"
                value={projectName || ""}
                onChange={handleProjectName}
                disabled={loading}
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Button variant="primary" onClick={handleApply} disabled={!valid}>
                Apply
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export default CModal;
