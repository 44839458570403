import axios from 'axios';
import qs from 'query-string';
import Cookies from 'universal-cookie';
import pkceChallenge from 'pkce-challenge';

const configuration = window.appdata;

export default class AuthService {
  constructor() {
    this.cookie = new Cookies();
  }
  setCookieValue(hashProperties, key) {
    if (hashProperties[key]) {
      this.cookie.remove(key);
      this.cookie.set(key, hashProperties[key], {
        path: '/',
      });
    }
  }

  async getTokens(code, challenge) {
    const cookie = new Cookies();
    const grant_type = code ? 'authorization_code' : 'refresh_token';
    const code_verifier = cookie.get('code_verifier');
    const redirect_uri = configuration.login_redirect_url;

    const instance = axios.create({
      baseURL: window.appdata.identity_v4_api_url,
    });

    challenge = pkceChallenge();

    const data = {
      grant_type,
      client_id: configuration.consumer_key_v4,
      code_challenge: challenge.code_challenge,
      code_challenge_method: "S256",
      code_verifier: code_verifier
    };

    if (code) {
      data.code = code;
      data.redirect_uri = redirect_uri;
    } else {
      data.refresh_token = cookie.get('refresh_token');
    }

    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',

      },
      data: qs.stringify(data),
      url: `${configuration.identity_v4_api_url}token`,
    };
    const response = await instance(options);
    cookie.set('access_token', response.data.access_token);
    cookie.set('id_token', response.data.id_token);
    cookie.set('refresh_token', response.data.refresh_token);
    if (challenge.code_verifier) {
      cookie.set('code_verifier', challenge.code_verifier);
    }
    return response;
  }
}
