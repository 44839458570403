import { observable, makeObservable, computed, action } from 'mobx';

const worksmanagerConstants = {
    emailValidation: /^([\w-]+(?:\.[\w-(+)]+)*(\+[\w]+)*)@((?:[\w-]+\.)*\w[\w-]*)\.([a-z]+)$/i
  };

class User {
  constructor(user) {
    this.setUser(user);

    makeObservable(this,{
      email: observable,
      firstName: observable,
      lastName: observable,
      phone: observable,
      profileImage: observable,
      lastSignedIn: observable,
      role: observable,
      roleUid: observable,
      roleKey: observable,
      userId: observable,
      accounts: observable,
      adminAccounts: observable,
      hasAdminAccessInAccounts: observable,
      fullName: computed,
      setUser: action,
      setAccounts: action,
      setRole: action,
      setAdminAccounts: action,
      setHasAdminAccessInAccounts: action
    })
  }

  
  setUser(user) {
    this.email = user.email;
    this.phone = user.phone;
    this.userId = user.userId;
    this.profileImage = user.profileImage;
    this.role = user.role;
    this.roleUid = user.roleUid;
    this.roleKey = user.roleKey;
    this.lifeStatus = user.lifeStatus;
    this.accounts = user.accounts;
    this.adminAccounts = user.adminAccounts;
    this.hasAdminAccessInAccounts = user.hasAdminAccessInAccounts;
    this.lastSignedIn = user.lastSignedIn;
    this.language = user.language;
    if (!user.firstName && !user.lastName) {
      const email = user.email || '';
      this.firstName = email.split('@')[0];
      this.lastName = email.substr(email.indexOf('@'), email.length);
    } else {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    }
  }

  setHasAdminAccessInAccounts(hasAdminAccessInAccounts) {
    this.hasAdminAccessInAccounts = hasAdminAccessInAccounts;
  }

  setRole(role) {
    this.role = role.name;
    this.roleKey = role.keyName;
    this.roleUid = role.roleUid;
  }
  setAccounts(accounts) {
    this.accounts = accounts;
  }

  setAdminAccounts(adminAccounts) {
    this.adminAccounts = adminAccounts;
  }

  get fullName() {
    const validEmailPattern = worksmanagerConstants.emailValidation;
    return `${this.firstName} ${validEmailPattern.test(this.lastName) ? `` : this.lastName
      }`;
  }
}

// decorate(User, {
//   email: observable,
//   firstName: observable,
//   lastName: observable,
//   Phone: observable,
//   profileImage: observable,
//   lastSignedIn: observable,
//   role: observable,
//   roleUid: observable,
//   roleKey: observable,
//   userId: observable,
//   accounts: observable,
//   adminAccounts: observable,
//   hasAdminAccessInAccounts: observable,
//   fullName: computed,
//   setUser: action,
//   setAccounts: action,
//   setRole: action,
//   setAdminAccounts: action,
//   setHasAdminAccessInAccounts: action
// });

export default User;
