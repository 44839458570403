import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Alert } from "@trimbleinc/modus-react-bootstrap";
import moment from 'moment';

const ExpirationDateChangeModal = (props) => {
    const propData = [
        {
          title: "Old Expiration Date",
          id: "oldExpirationDate",
          required: false,
          value: props.oldExpirationDate,
          disabled: true,
        },
        {
          title: "New Expiration Date",
          id: "newExpirationDate",
          required: true,
          disabled: false,
        }
      ];
    
    const [fieldVals, setFieldVals] = useState({});
    const [newExpirationDateIsValid, setNewExpirationDateIsValid] = useState(false);

    useEffect(() => {
        let oldExpDate = propData[0].value;

        if(oldExpDate === null) {
            // New expiration date
            let newTrialExpirationDate = moment(String(fieldVals.newExpirationDate), "YYYY-MM-DD", true);

            if(newTrialExpirationDate.isValid()) {
                setNewExpirationDateIsValid(true);
            } else {
                setNewExpirationDateIsValid(false);
            }
        } else {
            // Get old expiration date
            let oldTrialExpirationDate = moment(String(oldExpDate), "YYYY-MM-DD", true);

            // Get old expiration date plus one month
            let oldTrialExpirationDatePlusOneMonth = moment(String(oldExpDate), "YYYY-MM-DD", true).add(1, 'months');

            // New expiration date
            let newTrialExpirationDate = moment(String(fieldVals.newExpirationDate), "YYYY-MM-DD", true);

            // if format of new expiration date is valid and it is after the old expiration date and before one month after the
            // old expiration date, set newExpirationDateIsValid to true
            if(newTrialExpirationDate.isValid() && newTrialExpirationDate.isAfter(oldTrialExpirationDate) && newTrialExpirationDate.isBefore(oldTrialExpirationDatePlusOneMonth)) {
                setNewExpirationDateIsValid(true);
            } else {
                setNewExpirationDateIsValid(false);
            }
        }
    }, [fieldVals]);

    const handleApply = () => {
        // send request to change expiration date
        props.changeDateAndCheckResponse(fieldVals);
        props.handleClose();
    };

    const handleFieldChange = (e) => {
        const tmp = { [e.target.id]: e.target.value };
        setFieldVals({ ...fieldVals, ...tmp });
    };

    const onSub = (e) => {
      e.preventDefault();
    };
    
    return (
        <>
        <Modal show={props.showModal} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>WorksManager Trial Expiration Date</Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSub}>
                <Modal.Body>
                    {propData.map(field => {
                        return (
                            <Form.Group controlId={field.id} key={field.id}>
                                <Form.Label>{field.title}</Form.Label>
                                <Form.Control
                                    as="input"
                                    placeholder={field.title}
                                    required={propData?.required}
                                    onChange={handleFieldChange}
                                    autoComplete="off"
                                    value={field.value}
                                    disabled={field.disabled}
                                ></Form.Control>
                                {field.id == "newExpirationDate" &&
                                <Form.Text id="passwordHelpBlock" muted>
                                    The new expiration date must be less than one month after the old expiration date. Format must be YYYY-MM-DD.
                                </Form.Text>}
                            </Form.Group>
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleApply} disabled={!newExpirationDateIsValid}>
                        Change
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
        </>
    )
}

export default ExpirationDateChangeModal;