const widgetConfig = {
  id: 'xuarqpfjcesdl8kfhq7fw',
  collapsecomponentsbydefault: 'False',
  includecloudservices: 'true',
  customtitle: '',
  showinpanel: 'True',
  showtitlebar: 'True',
  showwidgettitle: 'True',
  allowcollapsible: 'True',
  allowfullscreen: 'False',
  showchildren: 'True',
  showcardchildtoggle: 'False',
  includehistorybarwithincard: 'True',
  includehistorybarwithincarddays: '7',
  statusfilter: '',
  filterbygroup: 'False',
  layoutstyle: 'List',
  cardlayoutnumofcols: '2',
  sortcomponentsby: '0',
  sortorder: '0',
  cloudcomponentlocation: '0',
  rootcomponentid: '275043',
  includeservices: '2',
  hideifnocontent: 'False',
  showfullpathofcomponents: 'True',
  showcomponentdescription: 'False',
  showtabbedcomponents: 'False',
  minimumrequiredrole: 'Anonymous',
  backgroundcolor: '',
  textcolor: '',
  titletextcolor: '',
  titlebackgroundcolor: '',
};
export const customWidgetConfig ={
  remoteAssist:{
    rootcomponentid:"275043",
    customtitle:"Remote Access Service Status" 
  }
}
export default widgetConfig;

