export default class deviceInfo {
    constructor(device = {}) {
        this.setDevice(device);
    }

    setDevice(device) {
        this.name = device.name;
        this.description = device.description;
        this.tid = device.id;
        this.createdTimeStamp = device.createdTimeStamp;
        this.lifeState = device.lifeState;
        this.updatedBy = device.updatedBy;
        this.updatedTimeStamp = device.updatedTimeStamp;
        this.code = device.code;
        this.serialNumber = device.serialNumber;
    }
}