import { configureStore } from '@reduxjs/toolkit'
import accountsReducers from '../Slices/accountSlice'
import devicesReducers from '../Slices/deviceSlice'
const store = configureStore({
    reducer: {
        accounts: accountsReducers,
        devices: devicesReducers,
    },
})

export default store;