import { Container, Spinner } from "@trimbleinc/modus-react-bootstrap";
import React from "react";
import CCard from "../Card/Card";
import "./status.scss";

import { Row, Col } from "@trimbleinc/modus-react-bootstrap";

import green from "../../assets/check_circle_green_36dp.svg";
import yellow from "../../assets/warning_yellow_36dp.svg";
import red from "../../assets/error_red_36dp.svg";
import StatusItem from "../StatusItem/StatusItem";

const iconMap = {
  S: <img src={green} alt="Success" style={{ height: "36px" }} />,
  F: <img src={red} alt="Failure" style={{ height: "36px" }} />,
  W: <img src={yellow} alt="Warning" style={{ height: "36px" }} />,
  L: (
    <Spinner
      animation="border"
      role="status"
      variant="primary"
      style={{
        height: "36px",
        width: "36px",
        fontSize: "13px",
      }}
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
  ),
  N: <div className="empty-circle" />,
};
const Line = () => {
  return (
    <Row className="m-0">
      <Col sm={2}>
        <div className="columnitem">
          <div className="line" />
        </div>
      </Col>
      <Col />
    </Row>
  );
};

const Status = (props) => {
  return (
    <CCard title="Status" width="100%">
      <Container className="py-4">
        {props.status.map((item, idx) => (
          <>
            <StatusItem
              key={idx}
              status={item.status}
              icon={iconMap[item.statusIcon]}
              isSelectable={
                idx !== 0 && item.statusIcon !== "L" && item.statusIcon !== "N"
              }
              selected={props.selected === idx + 1}
              setSelected={props.setSelected}
              index={idx + 1}
            />
            {idx !== props.status.length - 1 && <Line />}
          </>
        ))}
      </Container>
    </CCard>
  );
};

export default Status;
