import apiStatus from '../../../shared/apiStatus';
import AWSservice from './awsService'

export async function getDeviceBySerial(serial, setDiagnosticData, isFetching) {
    const awsService = new AWSservice();
    const res = await awsService.getDeviceBySerial(serial);
    setDiagnosticData(res)
    isFetching(false)
}

export async function getDeviceInformation(setRes, setResStatus, returnStatus, details) {
    setResStatus(apiStatus.INPROGRESS);
    const awsService = new AWSservice();
    const res = await awsService.getDeviceInformation(details.serialNumber, details.minDate, details.maxDate);
    returnStatus("Completed");
    setRes(res != null ? res : null);
    setResStatus(apiStatus.COMPLETED);
}

export async function RemoteITDiagWrapper(setRes, setResStatus, returnStatus, details) {

    //creates a UID to locate our async status
    const awsService = new AWSservice();
    await awsService.startRemoteITDiagnostics(details.deviceserialNumber, details.accountTid, details.accountName)

    let timeAroundLoop = 0;
    let elementsSent = [];

    //pass back any new elements of status, so we can update the ui.
    const updateStatusMsg = (status) => {
        if (status?.length) {
            status?.forEach(element => {
                if (!elementsSent.includes(element)) {
                    //Track the elements sent to avoid duplicates
                    elementsSent.push(element);
                    returnStatus(element)
                }
            });
        }
    }

    setResStatus(apiStatus.INPROGRESS)
    let resultResponse = await awsService.getRemoteITDiagnostics()
    while (resultResponse.status === 200) {
        if (resultResponse.data) {
            if (resultResponse.data.complete === false) {
                //Complete is a list that reports back on various stages of the UI.
                updateStatusMsg(resultResponse.data.completed)
                setResStatus(apiStatus.INPROGRESS)
            } else {
                setRes(resultResponse.data)
                setResStatus(apiStatus.COMPLETED);
                break;
            }
        } else {
            //callback
            setRes(resultResponse.data)
            setResStatus(apiStatus.COMPLETED);
            break;
        }

        await new Promise(r => setTimeout(r, 1000));

        if (timeAroundLoop > 150) {
            resultResponse.data['reason'] = 'Operation timeout, please try again';
            setRes(resultResponse)
            setResStatus(apiStatus.COMPLETED);
            break;
        } else {
            timeAroundLoop = timeAroundLoop + 1;
        }
        resultResponse = await awsService.getRemoteITDiagnostics()
    }
    returnStatus("Completed")
    setRes(resultResponse.data)
    setResStatus(apiStatus.COMPLETED);
}
