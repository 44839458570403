import { observable, makeObservable, action } from "mobx";
import * as Rox from "rox-browser";
import { featureMapping } from "./featureMappings";

class FeatureManagementStore {
  constructor(userStore) {
    this.features = {};
    this.globalContext = {};
    makeObservable(this, {
      features: observable,
      setUserContext: action,
      setFeatureFlags: action,
    });
    this.init();
  }
  setUserContext(user) {
    this.globalContext["User.UID"] = user?.userId;
    this.globalContext["User.Email"] = user?.email;
    Rox.setContext(this.globalContext);
    this.setFeatureFlags();
  }
  /** Setup the Rollout key */
  async init() {
    await Rox.setup(window.appdata.CloudBees_key, {});
    this.setFeatureFlags();
  }
  setFeatureFlags() {
    featureMapping.forEach(async (feature) => {
      /**
       * Dynamic API allows you to get flags value by their name (String).
       * If the flag does not exist, it creates the flag and returns the default value.
       * The feature.cloudbeesFlag will have the flag name as configured in Cloudbees Feature Management.
       * And we set 'false' as default value for all the flags.
       */
      this.features[feature.name] = Rox.dynamicApi.isEnabled(
        feature.cloudbeesFlag,
        false
      );
    });
  }
}

const featureManagementStore = new FeatureManagementStore();
export default featureManagementStore;
