import React from "react";
import CCard from "../../../shared/Card/Card";
import "./result.scss";

const Result = (props) => {
  return (
    <CCard title="Result" width="95%" height={props.height}>
      <div className="result-body">
        {props.isLoaded ? (
          <h5 className="res-loading">Please Wait</h5>
        ) : (
          <div className="parent">
            <div className="div1 result-title">{props.result.title}</div>
            {props.result.list && (
              <div className="div2">
                <ul>
                {props.result.list.map(listItem => (
                  <li>{listItem}</li>
                ))}
                </ul>
              </div>
            )}
            {props.result.table && (
              <div className="div2">
                <table>
                  <tbody>
                    {props.result.table.map((tablecontent, idx) => (
                      <tr>
                        <td>{tablecontent[0]}</td>
                        <td style={{ paddingLeft: "20px" }}>
                          {tablecontent[1]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {props.result.message && (
              <p className="message">{props.result.message}</p>
            )}
            {props.result.recommendation && (
              <>
                <p className="recommendation-title">Recommendation</p>
                <p className="recommendation-content">
                  {props.result.recommendation}
                </p>
              </>
            )}
          </div>
        )}
      </div>
    </CCard>
  );
};

export default Result;
