import React from "react";
import "./sideNav.scss";
import MenuItem from "../../shared/MenuItem";
import imgDesign from "../../assets/designs.svg";
import designActive from "../../assets/designs_active.svg";
import imgRemoteIt from "../../assets/tablet_white.svg";
import imgProvisioning from "../../assets/prov.svg";
import { observer } from "mobx-react";
const SideNav = observer((props) => {
  return (
    <div className="sidenav">
      {props.features.features.designToDevice && (
        <MenuItem
          title="Design To Device"
          img={imgDesign}
          active={designActive}
          width="19%"
          to="/design"
          paddingLeft="13px"
          paddingRight="20px"
        ></MenuItem>
      )}

      <MenuItem
        title="Provisioning"
        width="17%"
        img={imgProvisioning}
        active={imgProvisioning}
        paddingLeft="19px"
        paddingRight="28px"
        to="/provisioning"
      ></MenuItem>
      <MenuItem
        title="Remote Assist"
        width="17%"
        img={imgRemoteIt}
        active={imgRemoteIt}
        paddingLeft="19px"
        paddingRight="28px"
        to="/remoteAssist"
      ></MenuItem>
      <MenuItem
        title="Device Information"
        width="17%"
        img={imgDesign}
        active={designActive}
        paddingLeft="19px"
        paddingRight="28px"
        to="/deviceInformation"
      ></MenuItem>      
    </div>
  );
});

export default SideNav;
