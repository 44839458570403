import React, {useState} from "react";
import "./styles.scss";
import {Navigate, Route, Routes} from "react-router-dom";
import ModalWrapper from "../pages/modalWrapper.js";
import RemoteDiagnostics from "../pages/remoteAssist";
import DeviceInformation from "../pages/DeviceInformation"
import Provision from "../pages/Provisioning/provision";

import ProvisionModal from "../pages/Provisioning/provisionModal.js";
import DesignToDevice from "../pages/DesignToDevice/DesignToDevice.js";
import {observer} from "mobx-react";

const Router = observer((props) => {
    const [data, setData] = useState({});
    const [provisionData, setProvisionData] = useState({});
    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                        <h1 className="title">Welcome to the Trimble CCS Support Tool</h1>
                    }
                />
                {props.features.designToDevice && (
                    <Route
                        path="/design"
                        element={<DesignToDevice data={data} setData={setData} features={props.features}/>}
                    />
                )}
                <Route
                    path="/provisioning"
                    element={
                        <ModalWrapper
                            data={provisionData}
                            setData={setProvisionData}
                            page={Provision}
                            features={props.features}
                            modal={ProvisionModal}
                        />
                    }
                />
                <Route
                    path="/remoteAssist"
                    element={<RemoteDiagnostics data={data} setData={setData}/>}
                />
                <Route
                    path="/DeviceInformation"
                    element={<DeviceInformation data={data} setData={setData}/>}
                />
                <Route path="*" element={<Navigate to="/" replace/>}></Route>
            </Routes>
        </>
    );
});

export default Router;
