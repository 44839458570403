import axios from "axios";
const configuration = window.appdata;

const toHHMMSS = (createdTime, completedAt = null) => {
  let completedTime = null;
  if (!completedAt) completedTime = new Date();
  else completedTime = new Date(completedAt);
  createdTime = new Date(createdTime);
  let duration = (completedTime - createdTime) / 1000;
  let sec_num = parseInt(duration, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;
  let message = "";

  if (hours) {
    message += hours === 1 ? hours + " hour " : hours + " hours ";
  }
  if (minutes) {
    message += minutes === 1 ? minutes + " minute " : minutes + " minutes ";
  }
  if (seconds) {
    message += seconds === 1 ? seconds + " second " : seconds + " seconds ";
  }
  if (!completedAt) {
    message += "(till now)";
  }
  return message;
};
class StatusService {
  static async getData(url, params) {
    try {
      const response = await axios.get(url, {
        params,
      });
      return response.data;
    } catch (error) {
      return {
        ...error,
        isError: true,
        errorCode: error.response.status,
        errorMessage: JSON.parse(error.request.response)["message"],
      };
    }
  }
  async getConversionData(statusDetails, recommendations) {
    const { selectedDesign, selectedVersion } = statusDetails;
    const versionIndex = statusDetails.selectedDesign.versions.findIndex(
      (versionOfDesign) => versionOfDesign.version == selectedVersion
    );
    const params = {
      designId: selectedDesign.designId,
      designVersion: selectedVersion,
    };
    const errorCode =
      statusDetails.selectedDesign.versions[versionIndex].conversionErrorId;
    const url = `${configuration.api_url}${configuration.design_to_device_service_url}conversion_status`;
    let result = null;
    result = await StatusService.getData(url, params);
    let recommendation = {};
    if (result.isError) {
      if (statusDetails.selectedDesign.versions[versionIndex].comment) {
        recommendations.push({
          title: "Conversion Status - Success",
          message: `${statusDetails.selectedDesign.designName}.V${selectedVersion} has been published as a result of config file update`,
        });
        return "Success";
      }
      let recommend = {
        title: "Conversion Request Queue - Failed",
        message: result.errorMessage,
      };
      recommendations.push(recommend);
      return "Failure";
    }
    if (result) {
      switch (result.status) {
        case "SUCCESS":
          recommendation = {
            ...recommendation,
            title: "Design Conversion-Completed",
            table: [
              ["Created At", result.createdAt],
              ["Completed At", result.completedAt],
              ["Duration", toHHMMSS(result.createdAt, result.completedAt)],
            ],
          };
          recommendations.push(recommendation);
          return "Success";
        case "IN_PROGRESS":
          recommendation = {
            ...recommendation,
            title: "Design Conversion-In Progress",
            table: [
              ["Created At", result.createdAt],
              ["Completed At", "-"],
              ["Duration", toHHMMSS(result.createdAt) + " till now."],
            ],
            message:
              "The Design conversion is in progress. Please wait till the conversion completes",
            recommendation: "",
          };
          recommendations.push(recommendation);
          return "Warning";
        case "NOT_STARTED":
          recommendation = {
            ...recommendation,
            title: "Design Conversion-Not Started",
            table: [
              ["Created At", result.createdAt],
              ["Completed At", "-"],
              ["Duration", toHHMMSS(result.createdAt) + " till now."],
            ],
            message: `There are ${result.pendingJobs} pending jobs in the conversion request queue before this conversion. Please wait and try again sometime`,
            recommendation: "",
          };
          recommendations.push(recommendation);
          return "Warning";
        case "FAILED":
          recommendation = {
            ...recommendation,
            title: "Design Conversion-Failed",
            table: [
              ["Created At", result.createdAt],
              ["Completed At", result.completedAt],
              ["Duration", toHHMMSS(result.createdAt, result.completedAt)],
              ["Retry Count", result.retriesNumber],
            ],
          };
          if (errorCode) {
            const errorURL = `${configuration.api_url}${configuration.design_to_device_service_url}error/${errorCode}`;
            let errorResult = null;
            errorResult = await StatusService.getData(errorURL, {});
            if (errorResult) {
              recommendation["table"].push([
                "Error Code",
                errorResult.errorCode,
              ]);
              recommendation["recommendation"] = `${errorResult.action}`;
              if (errorResult.errorText) {
                recommendation["table"].push(["Error", errorResult.errorText]);
              }
              if (errorResult.reason) {
                recommendation["table"].push(["Reason", errorResult.reason]);
              }
            }
          } else {
            recommendation["recommendation"] =
              result.retriesNumber < 5
                ? `Please reachout to the WorksManager Team`
                : "This issue is related to TBC. Please reachout to TBC team";
          }

          recommendations.push(recommendation);
          return "Failure";
        default:
          recommendations.push({ message: "Conversion Job status - Unknown" });
          return "Failure";
      }
    }
    return null;
  }

  async checkTCCAvailability(statusDetails, recommendations) {
    const params = {
      projectName: statusDetails.selectedProject.name,
      accountId: statusDetails.selectedAccount,
      designVersion: statusDetails.selectedVersion,
      designId: statusDetails.selectedDesign.designId,
      deviceType: statusDetails.selectedDevice.deviceType,
      deviceName: statusDetails.selectedDevice.deviceName,
      serialNumber: statusDetails.selectedDevice.serialNumber,
    };
    const url = `${configuration.api_url}${configuration.design_to_device_service_url}tccavailability`;
    let result = null;
    result = await StatusService.getData(url, params);
    if (result.isError) {
      let recommend = {
        title: "TCC Availability check - Failed",
        message: result.errorMessage,
      };
      recommendations.push(recommend);
      return "Failure";
    }
    if (result.isPresent) {
      const allFilesPresent = result.missingFiles.length === 0
      let list=null
      let message = ''
      if(allFilesPresent){
        message = `All design files are available in path ${result.path}` 
      }
      else {
        message = `Above list of files are missing in path ${result.path}`
        list = result.missingFiles
      }
      let recommend = {
        title: "Design Availability in TCC",
        message,
        list
      };
      recommendations.push(recommend);
      return allFilesPresent? "Success" : "Failure";
    }
    let recommend = {
      title: "Design Availability in TCC",
      message: `File is not available in path ${result.path}`,
    };
    recommendations.push(recommend);
    return "Failure";
  }

  async checkFirmware(statusDetails, recommendations) {
    const givenDesignName = `${statusDetails.selectedDevice.deviceType}-${statusDetails.selectedDevice.serialNumber}`;
    const params = {
      deviceName: givenDesignName,
    };
    const url = `${configuration.api_url}${configuration.design_to_device_service_url}deviceFirmwareVersion`;
    let result = null;
    result = await StatusService.getData(url, params);
    if (result.isError) {
      let recommend = {
        title: "Device Sync  - Warning",
        message: "The device has not yet contacted with WorksManager",
        recommendation: "Please ask the user to sync the device from the field",
      };
      recommendations.push(recommend);
      return "Warning";
    }
    if (statusDetails.selectedDevice.deviceType !== "Tablet") {
      let recommend = {
        title: "Device Sync  - Success",
        table: [["Firmware Upgrade", "Not Required"]],
      };
      recommendations.push(recommend);
      return "Success";
    }
    if (result.isUpdateRequired) {
      let recommend = {
        title: "Device Sync  - Update Device",
        table: [
          ["Device Firmware Version", result.appVersion],
          ["Firmware Update", "Required"],
        ],
        recommendation:
          "Please ask the user to upgrade the firmware version > 1.2",
      };
      recommendations.push(recommend);
      return "Warning";
    }
    let recommend = {
      title: "Device Sync - Completed",
      table: [
        ["Device Firmware Version", result.appVersion],
        ["Firmware Update", "Not Required"],
      ],
    };
    recommendations.push(recommend);
    return "Success";
  }
}

const statusService = new StatusService();

export default statusService;
