import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "@trimbleinc/modus-react-bootstrap";
const FormModal = (props) => {
  const propData = props.config(props.data);
  const [fieldVals, setFieldVals] = useState({});
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if(props.showAccountName){
      const keys = propData.reduce((all, curr) => {
        all[curr.id] = "";
        return all;
      }, {});
      setFieldVals(keys);
    }
  },[props]);
  useEffect(() => {
    const valid = props.checkValid(fieldVals, propData);
    setIsValid(valid);
  }, [fieldVals]);

  const handleApply = () => {
    props.setData(fieldVals);
    setFieldVals(null);
    setIsValid(false);
    props.handleClose();
  };

  const handleFieldChange = (e) => {
    const tmp = { [e.target.id]: e.target.value };
    setFieldVals((prevValue) => {return{ ...prevValue, ...tmp }});
  };

  const onSub = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Modal show={props.showAccountName} onHide={props.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSub}>
          <Modal.Body>
            {/* Loop over the propFields array and construct a form. */}
            {propData.map((field) => {
              return (
                <Form.Group controlId={field.id} key={field.id}>
                  <Form.Label>{field.title}</Form.Label>
                  <Form.Control
                    as="input"
                    placeholder={field.title}
                    required={field?.required}
                    onChange={handleFieldChange}
                    autoComplete="off"
                    value={field.value}
                    disabled={field.disabled}
                  ></Form.Control>
                </Form.Group>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleApply} disabled={!isValid}>
              {props.buttonText}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default FormModal;
