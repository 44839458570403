import {
  Dropdown,
  Nav,
  Navbar
} from "@trimbleinc/modus-react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import authenticator from "../../shared/authenticator";

function logout() {
  authenticator.logout();
}

const Header = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        bg="white"
        style={{
          height: "7vh",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Navbar.Brand className=" mr-auto ml-2">
          <Link to="/">
            <img
              src="https://modus-bootstrap.trimble.com/img/trimble-logo.svg"
              width="107"
              height="25"
              className="img-fluid d-none d-sm-block"
              alt="home"
            />
            <img
              src="https://modus-bootstrap.trimble.com/img/trimble-icon.svg"
              className="d-block d-sm-none"
              height="25"
              width="25"
              alt="home"
            />
          </Link>
        </Navbar.Brand>
        <Dropdown navbar="true" alignRight={true}>
          <Dropdown.Toggle
            variant="text-dark"
            id="dropdown-basic"
            size="lg"
            bsPrefix
            className="btn-icon-only"
          >
            <i className="modus-icon material-icons">account_circle</i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* <Dropdown.Item href="#" style={{ textAlign: "center" }}>
              Profile Name
            </Dropdown.Item>
            <Dropdown.Item href="#" style={{ textAlign: "center" }}>
              email@trimble.com
            </Dropdown.Item>
            <Dropdown.Item href="#" style={{ textAlign: "center" }}>
              <Button variant="primary">Access MyTrimble</Button>
            </Dropdown.Item> */}
            <Dropdown.Item href="#" style={{ textAlign: "center" }}>
              <Nav.Link onClick={logout}>Sign out</Nav.Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
    </>
  );
};

export default Header;
