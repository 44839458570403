import axios from "axios";
const configuration = window.appdata;

export default class UserService {
  async getMe() {
    let apiCall = `${configuration.api_url}${configuration.connectedsite_api_url_path}users/me`;
    const response = await axios.get(apiCall);
    return response.data;
  }
}
