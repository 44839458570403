import { observer } from "mobx-react";
import React, { Fragment, useEffect, useState } from "react";

const ModalWrapper = (props) => {

  const [inputMode, setInputMode] = useState(false);

  useEffect(() => {
    //Reset the existing data everytime we navigate away from page.
    props.setData({})
  }, []);


  useEffect(() => {
    if (!Object.keys(props.data).length) {
      setInputMode(true);
    }
  }, [props.data]);


  const handleInput = () => {
    setInputMode(true);
  };
  const handleClose = () => {
    setInputMode(false);
  };


  /**
   * In order to make this component re-usable, I have allowed for the Page and Modal to be specified and passed as props.
   * 
   * I have also made it so that the page only renders once the modal has keys (data set)
   * This is a good idea as otherwise, the page will render before the modal sets data, which could be problemtatic if the page relys on the data.
   * 
   * I have also changed the statements to ternrary rather than && statements. If you use an and statement you can potentially render the first condition
   * to the DOM if the second isn't met. E.g. (5 && (<UI>)) can render "5" if <UI> is not provided.
   */

  return (
    <>
      {(inputMode && props.modal)?
        <props.modal
          data={props.data}
          show={inputMode}
          setData={props.setData}
          handleClose={handleClose}
        />
        : ""
      }
      {Object.keys(props.data).length ?
          <props.page 
            features={props.features}
            data={props.data} 
            onEdit={handleInput} 
          />
          : ""
      }
    </>
  );
};

export default observer(ModalWrapper);
