import { observable, makeObservable, action } from "mobx";
import User from "./user";
import apiStatus from "../../shared/apiStatus";
import axios from "axios";

class UserStore {
  constructor(userService, user = null) {
    this.setStatus(apiStatus.NOTSTARTED);
    this.setUser(user);
    this.userService = userService;

    makeObservable(this, {
      me: observable,
      status: observable,
      setUser: action,
      setStatus: action,
      init: action,
    });
  }

  setUser(user) {
    this.me = user ? new User(user) : user;
  }

  setStatus(status) {
    this.status = status;
  }
  async init() {
    try {
      this.tokenSource = axios.CancelToken.source();
      const user = await this.userService.getMe();
      this.setUser(user);
      return user;
    } catch (error) {
      console.error(error);
    }
  }
}

export default UserStore;
