import React, { useEffect, useReducer, useState } from "react";
import "./designstatus.scss";
import Status from "../../../shared/Status/Status";
import Result from "../Result/Result";
import statusConfig from "./statusConfig";
let recommendations = [];
let noOfStatus = 0;

const heightMap = {
  3: 287,
  4: 370,
  5: 423,
  6: 460,
};
const DesignStatus = (props) => {
  const [result, setResult] = useState({});
  const [selected, setSelected] = useState(0);
  const reduce = (state, action) => {
    switch (action.type) {
      case "init":
        return action.designStages;
      case "setStatus": {
        return state.map((designStage, idx) => {
          if (idx === action.id + 1) {
            return { ...designStage, statusIcon: action.status };
          } else return designStage;
        });
      }
      default: {
        console.warn("Wrong action type");
        return;
      }
    }
  };

  const [stages, dispatch] = useReducer(reduce, statusConfig.designStages);

  const handleStatus = async (gstatus, functions) => {
    setSelected(0);
    recommendations = [];
    noOfStatus = gstatus.length;
    dispatch({ type: "init", designStages: gstatus });
    for (let i = 0; i < functions.length; i++) {
      dispatch({ id: i, type: "setStatus", status: "L" });
      let result = await functions[i](
        props.data.statusDetails,
        recommendations
      );
      if (!result || result === "Failure") {
        dispatch({ id: i, type: "setStatus", status: "F" });
        break;
      } else if (result === "Warning") {
        dispatch({ id: i, type: "setStatus", status: "W" });
        break;
      }
      dispatch({ id: i, type: "setStatus", status: "S" });
    }
    setSelected(recommendations.length + 1);
  };
  useEffect(() => {
    if (props.data.statusDetails) {
      if (props.data.statusDetails.isVCLDesign) {
        handleStatus(
          statusConfig.designStagesWithVCL,
          statusConfig.functionsToInvokeVCL
        );
      } else {
        handleStatus(statusConfig.designStages, statusConfig.functionsToInvoke);
      }
    }
  }, [props.data.statusDetails, statusConfig]);

  useEffect(() => {
    let recommend = recommendations[selected - 2];
    setResult((prevState) => ({
      ...recommend
    }));
  }, [selected]);
  const handleSelect = (value) => {
    setSelected(value);
  };

  return (
    <div className="status-flex-container">
      <div className="child-flex-container status">
        <Status
          status={stages}
          selected={selected}
          setSelected={handleSelect}
        />
      </div>
      <div className="child-flex-container result">
        {
          <Result
            result={result}
            isLoaded={selected === 0}
            height={heightMap[noOfStatus]}
          />
        }
      </div>
    </div>
  );
};
export default DesignStatus;
