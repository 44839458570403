import React, { Fragment, useEffect, useState } from "react";
import Details from "./Details/Details";
import CModal from "./Modal/Modal";
import DesignStatus from "./DesignStatus/DesignStatus";

const DesignToDevice = (props) => {
  const [status, setStatus] = useState(false);
  const [inputMode, setInputMode] = useState(false);
  useEffect(() => {
    if (!props.data.orgName) {
      setInputMode(true);
    }
    return () => {
      props.setData("");
    };
  }, []);

  const handleInput = () => {
    setInputMode(true);
    setStatus(false);
  };
  const handleClose = () => {
    setInputMode(false);
    setStatus(false);
  };
  return (
    <Fragment>
      {
        <>
          <Details
            data={props.data}
            onEdit={handleInput}
            setStatus={setStatus}
            setData={props.setData}
          />
          {status && <DesignStatus data={props.data} />}
        </>
      }
      {inputMode && (
        <CModal
          data={props.data}
          show={inputMode}
          setData={props.setData}
          handleClose={handleClose}
        />
      )}
    </Fragment>
  );
};

export default DesignToDevice;
