import pkceChallenge from 'pkce-challenge';
import qs from 'query-string';
import Cookies from 'universal-cookie';
import AuthService from '../../shared/authenticator/authservice';

export const authentication = {
  cookie: new Cookies(),
  loginURL: `${window.appdata.identity_v4_api_url}${window.appdata.authorize_api_url}?scope=openid ${window.appdata.name}&client_id=${window.appdata.consumer_key_v4}&response_type=code&redirect_uri=${window.appdata.login_redirect_url}&code_challenge={code_challenge}&code_challenge_method=S256`,
  logoutURL: `${window.appdata.identity_v4_api_url}${window.appdata.logout_v4_api_url}?id_token_hint=id_token_hint_value&post_logout_redirect_uri=${window.appdata.login_redirect_url}`,
  authenticate() {
    const challenge = pkceChallenge();
    this.setCookieValue(challenge, 'code_verifier');
    window.location.assign(this.loginURL.replace('{code_challenge}', challenge.code_challenge));
  },
  redirectToLogout(id_token) {
    setTimeout(() => {
      window.location.assign(this.getLogoutURL(id_token));
    }, 0);
  },
  getLogoutURL(id_token) {
    return id_token
      ? this.logoutURL.replace('id_token_hint_value', id_token)
      : this.logoutURL.replace('id_token_hint=id_token_hint_value&', '');
  },
  logout() {
    const id_token = this.cookie.get('id_token');
    this.cookie.remove('code');
    this.cookie.remove('access_token');
    this.cookie.remove('id_token');
    this.redirectToLogout(id_token);
  },
  setCookieValue(hashProperties, key) {
    if (hashProperties[key]) {
      this.cookie.remove(key);
      this.cookie.set(key, hashProperties[key], {
        path: '/',
      });
    }
  },

  isAuthenticated(queryParams, path) {
    const searchParams = queryParams && qs.parse(queryParams);
    const access_token = this.cookie.get('access_token');
    if (searchParams && searchParams.error === "invalid_request" && !access_token) {
      this.redirectToLogout();
      return 'invalid_request';
    } else if (searchParams) {
      this.setCookieValue(searchParams, 'code');
      if (searchParams['code']) {
        const authService = new AuthService();
        authService.getTokens(searchParams['code']);
      }
      return path;
    } else if (this.cookie.get('code') || this.cookie.get('access_token')) {
      return true;
    } else {
      return false;
    }
  },
};

export default authentication;
