import Button from '@trimbleinc/modus-react-bootstrap/Button';
import Col from '@trimbleinc/modus-react-bootstrap/Col';
import Dropdown from '@trimbleinc/modus-react-bootstrap/Dropdown';
import Form from '@trimbleinc/modus-react-bootstrap/Form';
import Message from '@trimbleinc/modus-react-bootstrap/Message';
import Row from '@trimbleinc/modus-react-bootstrap/Row';
import Spinner from '@trimbleinc/modus-react-bootstrap/Spinner';
import React, {createRef, useCallback, useEffect, useState} from 'react';
import apiStatus from '../../shared/apiStatus';

import moment from 'moment';

import {useDispatch} from 'react-redux';
import {getAccounts} from './Slices/accountSlice';
import {getDevices} from './Slices/deviceSlice';

import {getDeviceBySerial, RemoteITDiagWrapper,} from './aws/awsServiceWrapper';
import {Modal} from '@trimbleinc/modus-react-bootstrap';

import Card from '@trimbleinc/modus-react-bootstrap/Card';
import Widget from '../../shared/Widget/Widget';
import {customWidgetConfig} from '../../shared/Widget/widgetConfig';
import './index.scss';

const RemoteDiagnostics = () => {
    //used for diagnostic table
    const [diagnosticData, setDiagnosticData] = useState(false);
    const [fetchingDiagnosticData, setFetchingDiagnosticData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [lastSelectedDiagDevice, setlastSelectedDiagDevice] = useState(false);
    const [hasSelectedDevice, setHasSelectedDevice] = useState(false);

    const [lastSelectedDevice, setLastSelectedDevice] = useState(undefined);
    const [lastSelectedDeviceName, setLastSelectedDeviceName] =
        useState(undefined);

    const [selectedAccount, setSelectedAccount] = useState('');
    const [accountShortOrgTyped, setAcctShortOrgTyped] = useState(undefined);
    const [selectedDevice, setSelectedDev] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [deviceList, setDeviceList] = useState([]);
    const [originalDeviceList, setOriginalDeviceList] = useState([]);
    const [accountShortOrgList, setAccountShortOrgList] = useState([]);
    const [accountTid, setAccountTid] = useState('');
    const [awsLambdaResult, setAWSLambdaResult] = useState(undefined);
    const [accountShortOrgCount, setAccountShortOrgCount] = useState(undefined);
    const [deviceCount, setDeviceCount] = useState(undefined);
    const [deviceTextFilter, setDeviceTextFilter] = useState(undefined);
    const [awsLambdaResultStatus, setAWSLambdaResultStatus] = useState(
        apiStatus.NOTSTARTED
    );
    const [shortOrgNameStatus, setShortOrgNameStatus] = useState(
        apiStatus.NOTSTARTED
    );
    const [deviceStatus, setDeviceStatus] = useState(apiStatus.NOTSTARTED);
    const [displayAll, setDisplayAll] = useState(false);
    const [statusMessages, setStatusMessages] = useState([]);
    const [isDisabledAccount, setIsDisabledAccount] = useState(false);
    const [isDisabledDevice, setIsDisabledDevice] = useState(false);
    const [isRemoteItDisabled, setIsRemoteItDisabled] = useState(true);

    let options = {};
    const accountFetchLimit = 76;
    const deviceFetchLimit = 76;
    let textInput = createRef();
    let textInputFilter = createRef();

    const dispatch = useDispatch();
    const infoIcon = (
        <i className="modus-icons" aria-hidden="true">
            info
        </i>
    );

    useEffect(() => {
        renderIt();
    });
    const getDevDiagnosis = useCallback(() => {
        setDiagnosticData();
        setFetchingDiagnosticData(true);
        let deviceserialNumber = selectedDevice;
        setlastSelectedDiagDevice(deviceserialNumber);
        getDeviceBySerial(
            deviceserialNumber,
            setDiagnosticData,
            setFetchingDiagnosticData
        );
    }, [selectedDevice]);

    useEffect(() => {
        if (originalDeviceList.length > 0) {
            const filteredDeviceList = originalDeviceList.filter(
                (device) =>
                    deviceTextFilter !== undefined ? device.name.toLowerCase().includes(deviceTextFilter.toLowerCase()) : device
            );
            filteredDeviceList.sort((a, b) => a.name.localeCompare(b.name));
            setDeviceList(filteredDeviceList);
        }
    }, [deviceTextFilter, originalDeviceList]);

    useEffect(() => {
        //If the user opens the table directly without analysis
        if (showModal) {
            //user changed device
            if (lastSelectedDiagDevice !== selectedDevice) {
                getDevDiagnosis();
            }
            //The modal is open and we are neither (already) fetching or have existing data.
            else if (!diagnosticData && !fetchingDiagnosticData) {
                getDevDiagnosis();
            }
        }
    }, [
        diagnosticData,
        fetchingDiagnosticData,
        getDevDiagnosis,
        lastSelectedDiagDevice,
        selectedDevice,
        showModal,
    ]);

    //used for the device table btn.
    useEffect(() => {
        if (selectedDevice.length) {
            setHasSelectedDevice(true);
        } else {
            setHasSelectedDevice(false);
        }
    }, [selectedDevice]);

    const textChangeAccount = (e) => {
        setAcctShortOrgTyped(e.target.value === '' ? undefined : e.target.value);
    };

    const textChangeFilterDevice = (e) => {
        setDeviceTextFilter(e.target.value === '' ? undefined : e.target.value);
    };

    const getForDeviceDetails = (e) => {
        e.preventDefault();
        //Remove slice usage.
        if (accountTid !== '') {
            setDeviceStatus(apiStatus.INPROGRESS);
            dispatch(getDevices({accountTid, deviceFetchLimit, options})).then(
                (originalPromiseResult) => {
                    const devices = originalPromiseResult.devices;
                    //We need to store the original, so that we are able to undo filters...
                    setDeviceList(devices);
                    setOriginalDeviceList(devices);
                    setDeviceCount(devices.length);
                    setDeviceStatus(apiStatus.COMPLETED);
                }
            );
        }
    };

    const setSelectedAccountShortOrg = (value) => {
        let accounts = accountShortOrgList.find((node) => node.name === value);
        setSelectedAccount(value);
        setAccountTid(accounts.tid);
    };

    const setSelectedDevice = (value) => {
        let deviceInfo = deviceList.find((node) => node.name === value);
        setSelectedDev(deviceInfo.serialNumber);
        setDeviceName(value);
    };

    const decodeRecommendation = (recommendation, jsonMessages) => {
        if (jsonMessages.description) {
            recommendation['descriptions'] = jsonMessages.description;
        }
        if (jsonMessages.recommendation) {
            recommendation['recommendation'] = jsonMessages.recommendation;
        }
        return recommendation;
    };

    function decodeResultsUI(data) {
        //first we must decode the data.
        const decodedData = decodeResults(data);
        if (decodedData === 'Result pending') {
            return decodedData;
        }
        //then construct a table.
        else {
            return (
                <ul>
                    <li>{decodedData?.deviceSerialNumber}</li>
                    <li>{decodedData?.pattern}</li>
                    <li>{decodedData?.descriptions}</li>
                    <li>{decodedData?.recommendation}</li>
                </ul>
            );
        }
    }

    function decodeResults(data) {
        if (data === undefined || data === null) {
            return 'Result pending';
        }
        if (data.status === 'failed') {
            let deviceName = lastSelectedDeviceName;
            let recommendations = {
                deviceSerialNumber:
                    deviceName === undefined
                        ? lastSelectedDevice
                        : deviceName + ' - (' + lastSelectedDevice + ')',
                recommendations: 'Failed to obtain any informtion for device',
            };
            return recommendations;
        } else {
            if (data.deviceSerialNumber) {
                let deviceName = lastSelectedDeviceName;
                let deviceSerialNumber = data.deviceSerialNumber;
                let recommendationsArray = data.recommendations;
                let recommendationsArray2 = recommendationsArray[deviceSerialNumber];

                let recommendations = {
                    deviceSerialNumber:
                        deviceName === undefined
                            ? lastSelectedDevice
                            : deviceName + ' - (' + data.deviceSerialNumber + ')',
                };
                if (
                    recommendationsArray2 !== undefined &&
                    recommendationsArray2.length > 0
                ) {
                    for (let index = 0; index < recommendationsArray2.length; ++index) {
                        const element = recommendationsArray2[index];

                        //use keys to get the reccomendations
                        let name = Object.keys(element)[0];
                        if (name) {
                            recommendations['pattern'] = name;
                            recommendations = decodeRecommendation(
                                recommendations,
                                element[name]
                            );
                        } else {
                            recommendations['recommendation'] = 'No Data Found';
                        }
                    }
                }
                return recommendations;
            } else if (data.reason) {
                let deviceName = lastSelectedDeviceName;
                let recommendations = {
                    deviceSerialNumber:
                        deviceName === undefined
                            ? lastSelectedDevice
                            : deviceName + ' - (' + lastSelectedDevice + ')',
                    recommendation: data.reason,
                };
                return recommendations;
            } else {
                let deviceName = lastSelectedDeviceName;
                let recommendations = {
                    deviceSerialNumber:
                        deviceName === undefined
                            ? lastSelectedDevice
                            : deviceName + ' - (' + lastSelectedDevice + ')',
                    recommendation: 'Failed to obtain any informtion for device',
                };
                return recommendations;
            }
        }
    }

    const resetDiagnosticTable = () => {
        // setDiagnosticData()
        setShowModal(false);
    };

    const renderdiagnosticTable = (diagData) => {
        if (!diagData?.devices?.length) {
            return 'No data was found for this device on RemoteIt. Please confirm you have selected the correct device and try again.';
        }

        //fields from the json that we want.
        const fields = {
            devicealias: 'Device alias',
            devicestate: 'Device state',
            lastinternalip: 'Internal IP',
            devicelastip: 'External ip',
            servicetitle: 'Service title',
            lastcontacted: 'Last contacted',
            hardwareid: 'Hardware id',
        };

        let headers = [];
        for (const [, value] of Object.entries(fields)) {
            headers.push(<th>{value}</th>);
        }
        //Loop over the fields that we want, and the devices in the payload.
        return (
            <table className="diagTable">
                {headers}
                {diagData?.devices?.map((item, index) => {
                    return (
                        <tr key={index}>
                            {Object.entries(fields).map((field, idx) => {
                                let key = field[0];
                                let data = item[key];
                                if (key === 'lastcontacted') {
                                    const date = moment(data);
                                    data = date.utc().format('DD-MM-YYYY, HH:MM:SS');
                                }
                                return <td key={idx}>{data}</td>;
                            })}
                        </tr>
                    );
                })}
            </table>
        );
    };

    const getRemoteItDiagnostics = () => {
        let deviceserialNumber = selectedDevice;
        let accountName = selectedAccount;
        let devName = deviceName;

        setDisplayAll(true);
        setStatusMessages([]);
        setAWSLambdaResult(undefined);

        setLastSelectedDeviceName(devName);
        setLastSelectedDevice(deviceserialNumber);
        //setLastdeviceList

        let details = {
            deviceserialNumber,
            accountTid,
            accountName,
        };
        //wrappers to update the state via our async method below.
        const setStatus = (newstatus) => {
            setStatusMessages((prevArray) => [...prevArray, newstatus]);
        };
        const setRes = (x) => {
            setAWSLambdaResult(x);
        };
        const setResStatus = (x) => {
            setAWSLambdaResultStatus(x);
        };

        //This sends off a request, and generates a unique ID to fetch the QUEUE, ASYNC
        RemoteITDiagWrapper(setRes, setResStatus, setStatus, details);
        //Start the table fetch just in case its not done
        getDevDiagnosis();
    };

    const resetUI = () => {
        setAccountShortOrgList([]);
        setSelectedAccount('');
        setDeviceList([]);
        setLastSelectedDevice();
        setSelectedDev('');
        setDeviceName('');
    };

    const getSearchForAcccounts = (e) => {
        e.preventDefault();
        resetUI();

        let shortOrgName = accountShortOrgTyped;
        setShortOrgNameStatus(apiStatus.INPROGRESS);

        //todo: remove slice usage.
        dispatch(getAccounts({accountFetchLimit, shortOrgName, options})).then(
            (originalPromiseResult) => {
                const accounts = originalPromiseResult.accounts;
                setAccountShortOrgList(accounts);
                setAccountShortOrgCount(accounts.length);
                setShortOrgNameStatus(apiStatus.COMPLETED);
            }
        );
    };

    const handleSelect = (e) => {
        setSelectedAccountShortOrg(e);
    };

    const handleSelectDevice = (e) => {
        setSelectedDevice(e);
    };

    let btnFromat = 'info';

    const renderIt = () => {
        if (accountShortOrgList.length > 0) {
            setAccountShortOrgList(
                accountShortOrgList.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                })
            );
        }
        //TODO: these should be moved into their own effect hooks rather than rendering every time, as it will slow the page.

        //displayAll = false means we are done searching..
        if (awsLambdaResultStatus === apiStatus.COMPLETED) {
            setDisplayAll(false);
        }
        //we are not searching, but check fields
        if (!displayAll) {
            //always enable account search
            setIsDisabledAccount(false);
            // show get diagnosis if we select an acc  + device
            if (selectedDevice && selectedAccount) {
                setIsRemoteItDisabled(false);
            } else {
                setIsRemoteItDisabled(true);
            }
            //search device, if we have no acc.
            if (selectedAccount) {
                setIsDisabledDevice(false);
            } else {
                setIsDisabledDevice(true);
            }
        }
        //searching, disable all
        else {
            setIsDisabledAccount(true);
            setIsDisabledDevice(true);
            setIsRemoteItDisabled(true);
        }
    };

    return (
        <div className="App">
            <Modal
                className="diagnostics-xl"
                size="xl"
                show={showModal}
                onHide={() => resetDiagnosticTable()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Diagnostic table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {fetchingDiagnosticData ? 'Loading...' : ''}
                    {diagnosticData ? renderdiagnosticTable(diagnosticData) : ''}
                </Modal.Body>
            </Modal>

            {/* Showing the status widget for the remote assist service */}
            <Widget
                title="RAStatusCast"
                className="RAStatuscastWidgetFrame"
                customWidgetConfig={customWidgetConfig.remoteAssist}
            />
            <Row className="row-info">
                <Card className="shadow">
                    <Form className="form-class" onSubmit={getSearchForAcccounts}>
                        <br></br>
                        <Row className="row-remoteIT-top">
                            <Col xs={3}>
                                <Form.Control
                                    onChange={textChangeAccount}
                                    as="input"
                                    className="focus"
                                    placeholder="Enter Short Org Name"
                                    ref={textInput}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Please enter the Trimble Short Org Name here, then press the 'Get Accounts' button"
                                ></Form.Control>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    className="btn-remoteIT"
                                    disabled={
                                        isDisabledAccount === true
                                            ? true
                                            : accountShortOrgTyped === undefined
                                                ? true
                                                : false
                                    }
                                    variant={btnFromat}
                                    type="submit"
                                    size="sm"
                                    onClick={getSearchForAcccounts}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Search for accounts by the specified short organisation name"
                                >
                                    Search Accounts
                                </Button>
                            </Col>
                            <Col xs={4}>
                                {shortOrgNameStatus === apiStatus.INPROGRESS && (
                                    <Spinner
                                        title="Loading account information, please wait"
                                        animation="border"
                                        role="status"
                                        variant="primary"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                )}
                                {shortOrgNameStatus === apiStatus.COMPLETED &&
                                accountShortOrgList.length ? (
                                    <Dropdown
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="List of the Acccount(s) matching the Short Org Name will appear here"
                                    >
                                        <Dropdown.Toggle
                                            className={'buttonWrap'}
                                            variant="light"
                                            id="dropdown-basic"
                                        >
                                            {!selectedAccount
                                                ? 'Select Account'
                                                : selectedAccount}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-md">
                                            {accountShortOrgList.map((account) => (
                                                <Dropdown.Item
                                                    disabled={isDisabledAccount === false ? false : true}
                                                    eventKey={account.name}
                                                    onSelect={handleSelect}
                                                    key={account.name}
                                                >
                                                    {account.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col xs={3}>
                                <Message
                                    className="message-RemoteIT"
                                    icon={infoIcon}
                                    variant={
                                        accountShortOrgList.length === 0 ? 'warning' : 'primary'
                                    }
                                    message={accountShortOrgList.length}
                                    show={accountShortOrgCount === undefined ? false : true}
                                >
                                    {' '}
                                    Accounts Found
                                </Message>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Form>
                    <Form className="form-class" onSubmit={getForDeviceDetails}>
                        <br></br>
                        <Row className="row-remoteIT-top">
                            <Col xs={3}>
                                <Form.Control
                                    title="Text in this field will be used to filter the device list dynamically"
                                    disabled={isDisabledDevice}
                                    onChange={textChangeFilterDevice}
                                    as="input"
                                    className="focus"
                                    placeholder="Enter Filter Text"
                                    ref={textInputFilter}
                                ></Form.Control>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    className="btn-remoteIT"
                                    title="Press button to get all devices on the account"
                                    variant={btnFromat}
                                    size="sm"
                                    onClick={getForDeviceDetails}
                                    disabled={isDisabledDevice}
                                    type="submit"
                                >
                                    Search Devices
                                </Button>
                            </Col>
                            <Col xs={4}>
                                {deviceStatus === apiStatus.INPROGRESS && (
                                    <Spinner
                                        title="Please wait, loading devices from the account"
                                        animation="border"
                                        role="status"
                                        variant="primary"
                                    >
                                        <span className="sr-only"></span>
                                    </Spinner>
                                )}
                                {deviceStatus === apiStatus.COMPLETED && deviceList.length ? (
                                    <Dropdown title="List of device from the account, this could be possibly filtered">
                                        <Dropdown.Toggle variant="light" id="dropdown-basic-device">
                                            {!selectedDevice
                                                ? 'Select Device'
                                                : deviceName}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-md">
                                            {deviceList.map((device) => (
                                                <Dropdown.Item
                                                    disabled={isDisabledDevice === false ? false : true}
                                                    eventKey={device.name}
                                                    onSelect={handleSelectDevice}
                                                    key={device.name}
                                                >
                                                    {device.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col xs={3}>
                                <Message
                                    className="message-RemoteIT"
                                    icon={infoIcon}
                                    variant={deviceList.length === 0 ? 'warning' : 'primary'}
                                    message={deviceList.length}
                                    show={deviceCount === undefined ? false : true}
                                >
                                    {' '}
                                    Devices Found
                                </Message>
                            </Col>
                            <Col></Col>
                        </Row>
                        <br></br>
                        <Row className="row-remoteIT-top">
                            <Col xs={2}>
                                <Button
                                    className="btn-remoteIT"
                                    variant={btnFromat}
                                    size="sm"
                                    onClick={() => {
                                        getRemoteItDiagnostics();
                                    }}
                                    title="Get the device diagnosis from RemoteIT"
                                    disabled={isRemoteItDisabled}
                                >
                                    Get Diagnosis from RemoteIT
                                </Button>
                                <Button
                                    style={{marginTop: '5%'}}
                                    className="btn-remoteIT"
                                    variant={btnFromat}
                                    size="sm"
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                    title="Show results table"
                                    disabled={!hasSelectedDevice}
                                >
                                    Show results table
                                </Button>
                            </Col>
                            <Col>
                                {awsLambdaResultStatus === apiStatus.INPROGRESS ? (
                                    <Spinner
                                        title="Please wait the Remote Diagnostic process is running, this my take a few minutes to execute"
                                        animation="border"
                                        role="status"
                                        variant="primary"
                                    >
                                        <span className="sr-only"></span>
                                    </Spinner>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                        <br></br>
                    </Form>
                </Card>
            </Row>
            <Row>
                <Col className="shadow-Column-left">
                    <Card className="shadow-row-lower">
                        <Card.Title className="title-remoteIT-info">
                            Diagnostic progress
                        </Card.Title>
                        <Card.Text className="text-remote-info">
                            <Message
                                show={
                                    awsLambdaResultStatus === apiStatus.NOTSTARTED ? false : true
                                }
                                icon={infoIcon}
                                variant="primary"
                                message="Diagnostic Status : "
                            >
                                Starting up...
                            </Message>

                            {statusMessages?.map((message, index) => (
                                <Message
                                    icon={infoIcon}
                                    variant="primary"
                                    message="Diagnostic Status : "
                                    key={index}
                                >
                                    {message}
                                </Message>
                            ))}
                        </Card.Text>
                    </Card>
                </Col>
                <Col className="shadow-Column-right">
                    <Card className="shadow-row-lower">
                        <Card.Title className="title-remoteIT-info">Diagnosis</Card.Title>
                        <Card.Text className="text-remote-info">
                            {awsLambdaResultStatus === apiStatus.NOTSTARTED
                                ? ''
                                : decodeResultsUI(awsLambdaResult)}
                        </Card.Text>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default RemoteDiagnostics;
