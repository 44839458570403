import Button from '@trimbleinc/modus-react-bootstrap/Button';
import Col from '@trimbleinc/modus-react-bootstrap/Col';
import Dropdown from '@trimbleinc/modus-react-bootstrap/Dropdown';
import Form from '@trimbleinc/modus-react-bootstrap/Form';
import Message from '@trimbleinc/modus-react-bootstrap/Message';
import Row from '@trimbleinc/modus-react-bootstrap/Row';
import Spinner from '@trimbleinc/modus-react-bootstrap/Spinner';
import React, {createRef, useEffect, useState} from 'react';
import apiStatus from '../../shared/apiStatus';
import {useDispatch} from 'react-redux';
import {getAccounts} from '../remoteAssist/Slices/accountSlice';
import {getDevices} from '../remoteAssist/Slices/deviceSlice';

import {getDeviceInformation} from '../remoteAssist/aws/awsServiceWrapper';

import Card from '@trimbleinc/modus-react-bootstrap/Card';
import './index.scss';
import moment from "moment/moment";
import {Modal} from "@trimbleinc/modus-react-bootstrap";

const DeviceMobileInformation = () => {
    const [startDate, setStartDate] = useState(subtractMonths(new Date(), 3));
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [showModal, setShowModal] = useState(false);

    const [selectedAccount, setSelectedAccount] = useState('');
    const [accountShortOrgTyped, setAcctShortOrgTyped] = useState(undefined);
    const [selectedDevice, setSelectedDev] = useState('');
    const [deviceName, setDeviceName] = useState('');
    const [deviceList, setDeviceList] = useState([]);
    const [originalDeviceList, setOriginalDeviceList] = useState([]);
    const [accountShortOrgList, setAccountShortOrgList] = useState([]);
    const [accountTid, setAccountTid] = useState('');
    const [awsLambdaResult, setAWSLambdaResult] = useState([]);
    const [awsLambdaResultToUse, setAWSLambdaResultToUse] = useState(undefined);
    const [accountShortOrgCount, setAccountShortOrgCount] = useState(undefined);
    const [deviceCount, setDeviceCount] = useState(undefined);
    const [deviceTextFilter, setDeviceTextFilter] = useState(undefined);
    const [awsLambdaResultStatus, setAWSLambdaResultStatus] = useState(apiStatus.NOTSTARTED);
    const [shortOrgNameStatus, setShortOrgNameStatus] = useState(apiStatus.NOTSTARTED);
    const [deviceStatus, setDeviceStatus] = useState(apiStatus.NOTSTARTED);
    const [displayAll, setDisplayAll] = useState(false);
    const [statusMessages, setStatusMessages] = useState([]);
    const [isDisabledAccount, setIsDisabledAccount] = useState(false);
    const [isDisabledDevice, setIsDisabledDevice] = useState(false);
    const [isRemoteItDisabled, setIsRemoteItDisabled] = useState(true);

    let options = {};
    const accountFetchLimit = 76;
    const deviceFetchLimit = 76;
    let textInput = createRef();
    let textInputFilter = createRef();

    function subtractMonths(date, months) {
        date.setMonth(date.getMonth() - months);
        return date.toISOString().split('T')[0];
    }

    const dispatch = useDispatch();
    const infoIcon = (
        <i className="modus-icons" aria-hidden="true">
            info
        </i>
    );

    useEffect(() => {
        renderIt();
    });

    useEffect(() => {
        if (originalDeviceList.length > 0) {
            const filteredDeviceList = originalDeviceList.filter(
                (device) =>
                    deviceTextFilter !== undefined ? device.name.toLowerCase().includes(deviceTextFilter.toLowerCase()) : device
            );
            filteredDeviceList.sort((a, b) => a.name.localeCompare(b.name));
            setDeviceList(filteredDeviceList);
        }
    }, [deviceTextFilter, originalDeviceList]);

    useEffect(() => {
        if (showModal) {
            //user changed device

        }
    }, [
        selectedDevice,
        awsLambdaResult,
        showModal
    ]);

    const convertTOISODate = (data) => {
        const date = moment(data);
        return date.utc().format('YYYY-MM-DD, HH:MM:SS');
    }
    const renderdiagnosticTable = (diagData) => {
        //fields from the json that we want.
        if (awsLambdaResult != null) {
            if (awsLambdaResult.status === 200) {
                if (awsLambdaResult.data.data.login.devices.total > 0) {
                    let totalCount = awsLambdaResult.data.data.login.devices.total;
                    const items = totalCount === 1 ? awsLambdaResult.data.data.login.devices.items[0] : awsLambdaResultToUse;
                    if (items !== undefined) {
                        const fields = {
                            timestamp: 'Timestamp',
                            expired: 'expired',
                            externalAddress: 'externalAddress',
                            state: 'State',
                            isp: 'ISP',
                            connectionType: 'connectionType',
                            city: 'City',
                            geoLocation: 'geoLocation'
                        };

                        let headers = [];
                        for (const [, value] of Object.entries(fields)) {
                            headers.push(<th>{value}</th>);
                        }
                        //Loop over the fields that we want, and the devices in the payload.
                        return (
                            <table className="diagTable">
                                {headers}
                                {items.events.items.map((item) => {
                                    return (
                                                <tr>
                                                    <th>{convertTOISODate(item?.timestamp)}</th>
                                                    <th>{item?.expired ? "True" : "False"}</th>
                                                    <th>{item?.externalAddress}</th>
                                                    <th>{item?.state}</th>
                                                    <th>{item?.geo.isp}</th>
                                                    <th>{item?.geo.connectionType}</th>
                                                    <th>{item?.geo.city}</th>
                                                    <th>{item?.geo.latitude},{item?.geo.longitude}</th>
                                                </tr>
                                    );
                        })}
                            </table>
                        );
                    }
                }
            }
        }
    };

    const textChangeAccount = (e) => {
        setAcctShortOrgTyped(e.target.value === '' ? undefined : e.target.value);
    };

    const textChangeFilterDevice = (e) => {
        setDeviceTextFilter(e.target.value === '' ? undefined : e.target.value);
    };

    const getCurrentSelectedStartDate = () => {
        return startDate;
    }

    const getCurrentSelectedEndDate = () => {
        return endDate;
    }

    const getForDeviceDetails = (e) => {
        e.preventDefault();
        //Remove slice usage.
        if (accountTid !== '') {
            setDeviceStatus(apiStatus.INPROGRESS);
            dispatch(getDevices({accountTid, deviceFetchLimit, options})).then(
                (originalPromiseResult) => {
                    const devices = originalPromiseResult.devices;
                    //We need to store the original, so that we are able to undo filters...
                    setDeviceList(devices);
                    setOriginalDeviceList(devices);
                    setDeviceCount(devices.length);
                    setDeviceStatus(apiStatus.COMPLETED);
                }
            );
        }
    };

    const onChangeStartDate = (event) => {
        setStartDate(event.target.value);
    };

    const onChangeEndDate = (event) => {
        setEndDate(event.target.value);
    };

    const setSelectedAccountShortOrg = (value) => {
        let accounts = accountShortOrgList.find((node) => node.name === value);
        setSelectedAccount(value);
        setAccountTid(accounts.tid);
    };

    const setSelectedDevice = (value) => {
        let deviceInfo = deviceList.find((node) => node.name === value);
        setSelectedDev(deviceInfo.serialNumber);
        setDeviceName(value);
    };


    const convertToRealNumber = (value) => {
        return value === undefined ? '' : Math.round(parseFloat(value));
    };

    function decodeResultsUI() {
        const availabilityToolTip = "Availability is a moving average representing the average availability of the service, i.e. 100% is online all the time, 50% half the time etc. This is an EMA (exponential moving average) with a time constant of one day.";
        const instabilityToolTip = "Instability is also a moving average (with a time constant of one day) representing the number of unscheduled offline events for the service, i.e. the number of time the device went offline per day (in average). This is only counting the times the device went offline because it did not communicate with our server. A clean shutdown of the device will not increase this metric. There are of course many reasons for a device to have unscheduled offline events causing a loss of communication, but most are power or network drops.";
        const chatterToolTip = "Chatter is the number of messages a device is sending per minute (a moving average, but with an hourly time constant) usually around 1.3 when the device is online steadily as this corresponds to about 45s ping time when idle.";
        const jitterToolTip = "Jitter is how often per minute, the device endpoint is changing (IP address/port change) this is an indication of network condition changes and is usually very low unless there is a problem with the network or NAT tables are corrupt.";
        const authRateToolTip = "AuthRate is how often the device authenticates with our servers per minute. This is usually very low as well, but if high, can indicate that the device identity may have been cloned causing excessive authentications.";
        if (awsLambdaResult != null) {
            if (awsLambdaResult.status === 200) {
                if (awsLambdaResult.data.data.login.devices.total > 0) {
                    let totalCount = awsLambdaResult.data.data.login.devices.total;
                    const items = totalCount === 1 ? awsLambdaResult.data.data.login.devices.items[0] : awsLambdaResultToUse;
                    return (
                        <div>
                            <table>
                                <tr>
                                    <th><b>Name</b></th>
                                    <th>{items?.name}</th>
                                </tr>
                                <tr>
                                    <th><b>Title</b></th>
                                    <th>{items?.title}</th>
                                </tr>
                                <tr>
                                    <th><b>Version</b></th>
                                    <th>{items?.version}</th>
                                </tr>
                                <tr>
                                    <th><b>Timestamp</b></th>
                                    <th>{items?.endpoint.timestamp}</th>
                                </tr>
                                <tr>
                                    <th><b>State</b></th>
                                    <th>{items?.endpoint.state}</th>
                                </tr>
                                <tr>
                                    <th title={availabilityToolTip}><b>Avalability</b></th>
                                    <th>{convertToRealNumber(items?.endpoint.availability)}</th>
                                </tr>
                                <tr>
                                    <th title={instabilityToolTip}><b>Instability</b></th>
                                    <th>{convertToRealNumber(items?.endpoint.instability)}</th>
                                </tr>
                                <tr>
                                    <th title={chatterToolTip}><b>Chatter</b></th>
                                    <th>{convertToRealNumber(items?.endpoint.chatter)}</th>
                                </tr>
                                <tr>
                                    <th title={jitterToolTip}><b>Jitter</b></th>
                                    <th>{convertToRealNumber(items?.endpoint.jitter)}</th>
                                </tr>
                                <tr>
                                    <th title={authRateToolTip}><b>AuthRate</b></th>
                                    <th>{items?.endpoint.authRate}</th>
                                </tr>
                                <tr>
                                    <th><b>country</b></th>
                                    <th>{items?.endpoint.geo.country}</th>
                                </tr>
                                <tr>
                                    <th><b>Connection Type</b></th>
                                    <th>{items?.endpoint.geo.connectionType}</th>
                                </tr>
                                <tr>
                                    <th><b>isp</b></th>
                                    <th>{items?.endpoint.geo.isp}</th>
                                </tr>
                            </table>
                        </div>
                    );
                }
            }
        }
    }

    const getRemoteItInformation = () => {
        let minDate = startDate.toString("YYYY-MM-DD");
        let maxDate = endDate.toString("YYYY-MM-DD");;
        let serialNumber = selectedDevice;
        let details = {serialNumber, minDate, maxDate};

        //wrappers to update the state via our async method below.
        const setStatus = (newstatus) => {
            setStatusMessages((prevArray) => [...prevArray, newstatus]);
        };
        const setRes = (x) => {
            setAWSLambdaResult(x);
        };
        const setResStatus = (x) => {
            setAWSLambdaResultStatus(x);
        };

        setStatusMessages(statusMessages => [] );
        setResStatus(apiStatus.NOTSTARTED)
        //need to clear message, and disable button
        getDeviceInformation(setRes, setResStatus, setStatus, details);
    };

    const resetUI = () => {
        setAccountShortOrgList([]);
        setSelectedAccount('');
        setDeviceList([]);
        setSelectedDev('');
        setDeviceName('');
        setAWSLambdaResult([]);
    };
    const getSearchForAcccounts = (e) => {
        e.preventDefault();
        resetUI();

        let shortOrgName = accountShortOrgTyped;
        setShortOrgNameStatus(apiStatus.INPROGRESS);

        dispatch(getAccounts({accountFetchLimit, shortOrgName, options})).then(
            (originalPromiseResult) => {
                const accounts = originalPromiseResult.accounts;
                setAccountShortOrgList(accounts);
                setAccountShortOrgCount(accounts.length);
                setShortOrgNameStatus(apiStatus.COMPLETED);
            }
        );
    };

    const handleSelectDevice = (e) => {
        setSelectedDevice(e);
    };

    const handleSelectItems = (e) => {
        let selectItem = awsLambdaResult.data.data.login.devices.items.find((node) => node.endpoint.timestamp === e);
        setAWSLambdaResultToUse(selectItem)
    }

    let btnFormatInfo = 'info';
    let btnFormatLink = 'link' ;

    const handleSelect = (e) => {
     setSelectedAccountShortOrg(e);
    };

    const renderIt = () => {
        if (accountShortOrgList.length > 0) {
            setAccountShortOrgList(
                accountShortOrgList.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                })
            );
        }
        if (awsLambdaResultStatus === apiStatus.COMPLETED) {
            setDisplayAll(false);
        }
        if (!displayAll) {
            setIsDisabledAccount(false);
            if (selectedDevice && selectedAccount) {
                setIsRemoteItDisabled(false);
            } else {
                setIsRemoteItDisabled(true);
            }
            if (selectedAccount) {
                setIsDisabledDevice(false);
            } else {
                setIsDisabledDevice(true);
            }
        } else {
            setIsDisabledAccount(true);
            setIsDisabledDevice(true);
            setIsRemoteItDisabled(true);
        }
    };

    const resetDiagnosticTable = () => {
        // setDiagnosticData()
        setShowModal(false);
    };

    const disableExtraEventInfoBuutton = () => {
        let enabled = false;
        if (awsLambdaResult != null) {
            if (awsLambdaResult.status === 200) {
                if (awsLambdaResult.data.data.login.devices.total > 0) {
                    let totalCount = awsLambdaResult.data.data.login.devices.total;
                    enabled = totalCount === 1 ? true : awsLambdaResultToUse !== undefined ? true : false;
                }
            }
        }
        return !enabled;
    }
    const showExtraEventsInformation = () => {
        return (
            <Button
                disabled={disableExtraEventInfoBuutton()}
                show={!disableExtraEventInfoBuutton()}
                className="btn-remoteITEventInfo"
                variant={btnFormatLink}
                size="sm"
                onClick={() => {
                    setShowModal(true);
                }}
                data-toggle="tooltip"
                data-placement="top"
                title="Extra Event Information from the Query of Remote IT"
                type="submit">
                Extra Event Information
            </Button>
        );
    }

    return (
        <div className="App">
            <Modal
                className="diagnostics-xl"
                size="xl"
                show={showModal}
                onHide={() => resetDiagnosticTable()}>
                <Modal.Header closeButton>
                    <Modal.Title>Event History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {awsLambdaResult ? renderdiagnosticTable(awsLambdaResult) : ''}
                </Modal.Body>
            </Modal>
            <Row className="row-info">
                <Card className="shadow">
                    <Form className="form-class" onSubmit={getSearchForAcccounts}>
                        <br></br>
                        <Row className="row-remoteIT-top">
                            <Col xs={3}>
                                <Form.Control
                                    onChange={textChangeAccount}
                                    as="input"
                                    className="focus"
                                    placeholder="Enter Short Org Name"
                                    ref={textInput}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Please enter the Trimble Short Org Name here, then press the 'Get Accounts' button"
                                ></Form.Control>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    className="btn-remoteIT"
                                    disabled={
                                        isDisabledAccount === true
                                            ? true
                                            : accountShortOrgTyped === undefined
                                                ? true
                                                : false
                                    }
                                    variant={btnFormatInfo}
                                    type="submit"
                                    size="sm"
                                    onClick={getSearchForAcccounts}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Search for accounts by the specified short organisation name"
                                >
                                    Search Accounts
                                </Button>
                            </Col>
                            <Col xs={4}>
                                {shortOrgNameStatus === apiStatus.INPROGRESS && (
                                    <Spinner
                                        title="Loading account information, please wait"
                                        animation="border"
                                        role="status"
                                        variant="primary"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                )}
                                {shortOrgNameStatus === apiStatus.COMPLETED &&
                                accountShortOrgList.length ? (
                                    <Dropdown
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="List of the Acccount(s) matching the Short Org Name will appear here"
                                    >
                                        <Dropdown.Toggle
                                            className={'buttonWrap'}
                                            variant="light"
                                            id="dropdown-basic"
                                        >
                                            {!selectedAccount
                                                ? 'Select Account'
                                                : selectedAccount}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-md">
                                            {accountShortOrgList.map((account) => (
                                                <Dropdown.Item
                                                    disabled={isDisabledAccount === false ? false : true}
                                                    eventKey={account.name}
                                                    onSelect={handleSelect}
                                                    key={account.name}
                                                >
                                                    {account.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col xs={3}>
                                <Message
                                    className="message-RemoteIT"
                                    icon={infoIcon}
                                    variant={
                                        accountShortOrgList.length === 0 ? 'warning' : 'primary'
                                    }
                                    message={accountShortOrgList.length}
                                    show={accountShortOrgCount === undefined ? false : true}
                                >
                                    {' '}
                                    Accounts Found
                                </Message>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Form>
                    <Form className="form-class" onSubmit={getForDeviceDetails}>
                        <Row className="row-remoteIT-top">
                            <Col xs={3}>
                                <Form.Control
                                    title="Text in this field will be used to filter the device list dynamically"
                                    disabled={isDisabledDevice}
                                    onChange={textChangeFilterDevice}
                                    as="input"
                                    className="focus"
                                    placeholder="Enter Filter Text"
                                    ref={textInputFilter}
                                ></Form.Control>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    className="btn-remoteIT"
                                    title="Press button to get all devices on the account"
                                    variant={btnFormatInfo}
                                    size="sm"
                                    onClick={getForDeviceDetails}
                                    disabled={isDisabledDevice}
                                    type="submit"
                                >
                                    Search Devices
                                </Button>
                            </Col>
                            <Col xs={4}>
                                {deviceStatus === apiStatus.INPROGRESS && (
                                    <Spinner
                                        title="Please wait, loading devices from the account"
                                        animation="border"
                                        role="status"
                                        variant="primary"
                                    >
                                        <span className="sr-only"></span>
                                    </Spinner>
                                )}
                                {deviceStatus === apiStatus.COMPLETED && deviceList.length ? (
                                    <Dropdown
                                        title="List of device from the account, this could be possibly filtered">
                                        <Dropdown.Toggle variant="light" id="dropdown-basic-device">
                                            {!selectedDevice
                                                ? 'Select Device'
                                                : deviceName}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-md">
                                            {deviceList.map((device) => (
                                                <Dropdown.Item
                                                    disabled={isDisabledDevice === false ? false : true}
                                                    eventKey={device.name}
                                                    onSelect={handleSelectDevice}
                                                    key={device.name}
                                                >
                                                    {device.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col xs={3}>
                                <Message
                                    className="message-RemoteIT"
                                    icon={infoIcon}
                                    variant={deviceList.length === 0 ? 'warning' : 'primary'}
                                    message={deviceList.length}
                                    show={deviceCount === undefined ? false : true}
                                >
                                    {' '}
                                    Devices Found
                                </Message>
                            </Col>
                            <Col></Col>

                            <Form className="form-class">
                                <Col xs={2}>
                                    <Form.Label className="pr-2">Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        onChange={onChangeStartDate}
                                        value={getCurrentSelectedStartDate()}
                                        disabled={isRemoteItDisabled}
                                    />

                                    <Form.Label className="pr-2">End Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        onChange={onChangeEndDate}
                                        value={getCurrentSelectedEndDate()}
                                        disabled={isRemoteItDisabled}
                                    />
                                </Col>
                            </Form>
                        </Row>
                        <Row className="row-remoteIT-top">
                            <Col xs={2}>
                                <Button
                                    className="btn-remoteIT"
                                    variant={btnFormatInfo}
                                    size="sm"
                                    onClick={() => {
                                        getRemoteItInformation();
                                    }}
                                    title="Get the device diagnosis from RemoteIT"
                                    disabled={isRemoteItDisabled}
                                >
                                    Get Diagnosis from RemoteIT
                                </Button>
                            </Col>
                            <Col>
                                {awsLambdaResultStatus === apiStatus.INPROGRESS ? (
                                    <Spinner
                                        title="Please wait the Remote Diagnostic process is running, this my take a few minutes to execute"
                                        animation="border"
                                        role="status"
                                        variant="primary"
                                    >
                                        <span className="sr-only"></span>
                                    </Spinner>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                    </Form>
                </Card>
            </Row>
            <Row>
                <Col className="shadow-Column-left">
                    <Card className="shadow-row-lower">
                        <Card.Title className="title-remoteIT-info">

                        </Card.Title>
                        <Card.Text className="text-remote-info">
                            <Message
                                show={
                                    awsLambdaResultStatus === apiStatus.NOTSTARTED ? false : true
                                }
                                icon={infoIcon}
                                variant="primary"
                                message="Diagnostic Status : "
                            >
                                Starting up...
                            </Message>

                            {statusMessages?.map((message, index) => (
                                <Message
                                    icon={infoIcon}
                                    variant="primary"
                                    message="Diagnostic Status : "
                                    key={index}
                                >
                                    {message}
                                </Message>
                            ))}
                            {awsLambdaResult !== null && awsLambdaResult.status === 200? (
                                <Dropdown>
                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                Select Item
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-md">
                            {awsLambdaResult.data.data.login.devices.items.map((account) => (
                                <Dropdown.Item
                                disabled={isDisabledAccount === false ? false : true}
                            eventKey={account.endpoint.timestamp}
                            onSelect={handleSelectItems}
                            key={account.endpoint.timestamp}
                        >
                            {convertTOISODate(account.endpoint.timestamp)}
                        </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>) : ''
                            }
                        </Card.Text>
                    </Card>
                </Col>
                <Col className="shadow-Column-right">
                    <Card className="shadow-row-lower">
                        <Card.Title className="title-remoteIT-info">Status {showExtraEventsInformation()}
                        </Card.Title>
                        <Card.Text className="text-remote-info">
                            {awsLambdaResultStatus === apiStatus.NOTSTARTED
                                ? ''
                                : decodeResultsUI(awsLambdaResult)}
                        </Card.Text>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DeviceMobileInformation;
