import { Card } from "@trimbleinc/modus-react-bootstrap";
import "./styles.scss";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const MenuItem = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === props.to) {
      setClasses({
        img: props.active,
        backgroundColor: "#252A2E",
        color: "#FFBE00",
      });
    } else {
      setClasses({
        img: props.img,
        backgroundColor: "transparent",
        color: "white",
      });
    }
  }, [location, props.to, props.img, props.active]);
  const [classes, setClasses] = useState({
    img: props.img,
    backgroundColor: "transparent",
    color: "white",
  });
  const width = props.width || "10%";
  const paddingLeft = props.paddingLeft || "10%";
  const paddingRight = props.paddingRight || "10%";
  return (
    <>
      <Card
        as="div"
        className="cardsm"
        style={{
          backgroundColor: classes.backgroundColor,
          paddingTop: "5%",
          cursor: "pointer",
        }}
      >
        <NavLink to={props.to}>
          <Card.Title
            style={{
              color: classes.color,
              paddingLeft: paddingLeft,
              fontSize: "13px",
            }}
          >
            <Card.Img
              src={classes.img}
              style={{
                width: width,
                height: "auto",
                paddingRight: paddingRight,
              }}
            />
            {props.title}
          </Card.Title>
        </NavLink>
      </Card>
    </>
  );
};

export default MenuItem;
