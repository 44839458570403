import apiStatus from '../../../shared/apiStatus';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RemoteDiagnosticsStore from '../remoteDiagnosticsStore'

export const getDevices = createAsyncThunk(
    "device/getDevices",
    async (accountInfo, {rejectWithValue}) => {
        try{
            const remoteDiagnosticsStore = new RemoteDiagnosticsStore();
            const response = await remoteDiagnosticsStore.getAllDeviceInfo(accountInfo.accountTid, 0, accountInfo.deviceFetchLimit, accountInfo.options)
            return response;
        } catch (err){
            console.log(err);
            return rejectWithValue(err)
        }
    }
);

const initialState = {
    devices: [],
    status: apiStatus.NOTSTARTED.toString(),
};

const accountsSlice = createSlice({
    name: "Device",
    initialState,    
    extraReducers: {
        [getDevices.pending]: (state, action) => {
            state.status = apiStatus.INPROGRESS.toString();
        },
        [getDevices.fulfilled]: (state, action) => {
            state.status = apiStatus.COMPLETED.toString();
            action.devices = action.payload
        },
        [getDevices.rejected]: (state, action) => {
            state.status = apiStatus.WAITING.toString();
        },
    }
})

export default accountsSlice.reducer;