import React from 'react';
import { Navigate } from 'react-router-dom';
import authenticator from '../authenticator';

function RedirectTID() {
  authenticator.authenticate();
  return null;
}

export const PrivateRoute = (location) => {
  const authenticateValue = authenticator.isAuthenticated(location.location.search, location.location.pathname);
  let authenticatedComponent = '';

  if (authenticateValue === true) {
    authenticatedComponent = location.children;
  } else if (authenticateValue === false) {
    authenticatedComponent = <RedirectTID />;
  } else if (authenticateValue !== "invalid_request") {
    authenticatedComponent = <Navigate to={authenticateValue} />;
  } else {
    authenticatedComponent = ''
  }
  return <React.Fragment>{authenticatedComponent}</React.Fragment>;
};
