import React from "react";
import "./card.scss";
import imgEdit from "../../assets/edit.svg";
import { Card, Button, Col } from "@trimbleinc/modus-react-bootstrap";

const CCard = (props) => {
  const width = props.width || "auto";
  const height = props.height || "auto";
  return (
    <Card
      as="div"
      style={{
        borderRadius: "10px",
        marginLeft: "2%",
        marginRight: "2%",
        marginBottom: "3px",
        width: width,
        minHeight: height,
      }}
    >
      <Card.Body style={{ paddingBottom: "0px", paddingTop: "0px" }}>
        <div
          className="card-flex"
          style={{
            padding: "5px",
            margin: "0 -1rem",
            borderBottom: "1px solid #ebebed",
            minHeight: "43px",
          }}
        >
          <div className="ccard-title" style={{ flex: 8, paddingTop: "8px" }}>
            {props.title}
          </div>
          <div className="ccard-title" style={{ flex: 89 }}></div>
          <div className="ccard-title" style={{ flex: 3 }}>
            {props.isEdit && (
              <span className="card-clickable" onClick={props.onEdit}>
                <img src={imgEdit} title="Edit" alt="Edit" />
              </span>
            )}
          </div>
        </div>
        {props.children}
        {props.buttonTitle && (
          <Col>
            <div className="card-button">
              <Button
                class="btn-primary"
                href="#"
                style={{ textAlign: "center" }}
                onClick={props.buttonAction}
                disabled={props.buttonDisabled}
              >
                {props.buttonTitle}
              </Button>
            </div>
          </Col>
        )}
      </Card.Body>
    </Card>
  );
};

export default CCard;
