import axios from 'axios';
import qs from 'query-string';
const configuration = window.appdata;

export default class RemoteDiagnosticsService {
  async getDevicesList(
    accountTid,
    from,
    limit,
    cancelToken,
  ) {
    const querystring = qs.stringify({
      from,
      limit,
      accountTid
    });
    let queryParam = '';
    if (querystring) {
      queryParam = `?${querystring}`;
    }
    let apiCall =  `${configuration.api_url}${configuration.amazon_api_url}devicelist${queryParam}`;
    const response = await axios.get(apiCall, cancelToken);
    return response.data;
  }

  async searchAccountsShortOrgName(
    from,
    limit,
    shortOrgName,
    cancelToken,
  ) {

    //If it has spaces, wrap in quotes.
    if(shortOrgName.indexOf(' ') >= 0){
        shortOrgName = `'${shortOrgName}'`
    }
    
    const querystring = qs.stringify({
      from,
      limit,
      accountText: shortOrgName
    });
    let queryParam = '';
    if (querystring) {
      queryParam = `?${querystring}`;
    }

    let apiCall =  `${configuration.api_url}${configuration.amazon_api_url}shortorgaccount${queryParam}`;
    const response = await axios.get(apiCall, cancelToken)
    return response;
  }
}