import axios from 'axios';
import qs from 'query-string';

const configuration = window.appdata;
export default class RemoteITService {
    setUnqiue() {
        const d = new Date();
        this.epochTime = d.valueOf();
    }

    getUnquie() {
        return this.epochTime;
    }

    async startRemoteITDiagnostics(deviceSerialNumber, accountTID, accountName) {
        try {
            this.setUnqiue();
            const querystring = {
                "body": {
                    "deviceSerialNumber": deviceSerialNumber,
                    "accountTID": accountTID,
                    "accountName": accountName,
                    "unquieIdsupplied": this.getUnquie()
                }
            };

            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'InvocationType': 'Event'
                }
            }
            let apiCall = `${configuration.api_url}${configuration.amazon_api_url}remoteit/queue`;
            const response = await axios.post(apiCall, querystring, headers);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    async getRemoteITDiagnostics() {
        try {
            const querystring = {
                "body": {
                    "unquieIdsupplied": this.getUnquie()
                }
            };
            let apiCall = `${configuration.api_url}${configuration.amazon_api_url}remoteit/poll`;
            const response = await axios.post(apiCall, querystring);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    async getDeviceBySerial(serial) {

        try {

            const querystring = qs.stringify({
                serial: serial
            });
            let queryParam = '';
            if (querystring) {
                queryParam = `?${querystring}`;
            }

            let apiCall = `${configuration.api_url}${configuration.amazon_api_url}getdevice${queryParam}`;
            const response = await axios.get(apiCall);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getDeviceInformation(serialNumber, minDate, maxDate) {

        try {
            const querystring = {
                "body": {
                    "serialNumber": serialNumber,
                    "minDate": minDate,
                    "maxDate": maxDate
                }
            };
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'InvocationType': 'Event'
                }
            }
            let apiCall =  `${configuration.api_url}${configuration.amazon_api_url}GetExtraDeviceInfoFromRemoteIT`;
            const response =  await axios.post(apiCall, querystring, headers);
            return response;
        } catch (error) {
            console.log(error);
        }
    }

}