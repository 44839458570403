import { differenceBy } from 'lodash';
import apiStatus from '../../shared/apiStatus';
import RemoteDiagnosticsService from './remoteDiagnosticsService'
import accountInfo from './Slices/accountInfo'
import deviceInfo from './Slices/deviceInfo'

export default class RemoteDiagnosticsStore {
    constructor(
    ) {
        let accountDetailInfo = [];
        let deviceDetailInfo = [];

        this.remoteDiagnosticsService = new RemoteDiagnosticsService();
        this.hasMoreAccount = true;
        this.hasMoreAccountShortOrgName = true;
        this.hasMoreDevice = true;
        
        this.lastAccountStartShortOrgNameIndex = 0;

        //Functions
        this.setAccountsShortOrgName = this.setAccountsShortOrgName.bind(this);
        this.setDevices = this.setDevices.bind(this);

        this.setAccountsShortOrgName(accountDetailInfo);
        this.setDevices(deviceDetailInfo);
        this.setAccountInfoShortOrgName(undefined);
        this.setDeviceInfo(undefined);
        this.setAccountShortOrgNameStatus(apiStatus.NOTSTARTED);
        this.setDeviceStatus(apiStatus.NOTSTARTED);
    }

    setAccountInfoShortOrgName(value) {
        this.accountShortOrgNameInfo = value;
    }

    setDeviceInfo(value) {
        this.deviceInfo = value;
    }

    setAccountShortOrgNameStatus(status) {
        this.statusAccountShortOrgName = status;
    }

    getAccountShortOrgNameStatus() {
        return this.statusAccountShortOrgName;
    }

    setDeviceStatus(status) {
        this.statusDevice = status;
    }

    getDeviceStatus() {
        return this.statusDevice;
    }


    setDevices(deviceDetailInfo, concat) {
        const mappedDevice = deviceDetailInfo.map((device) => { return new deviceInfo(device) });

        this.deviceDetailInfo = concat ? this.deviceDetailInfo.concat(mappedDevice) : mappedDevice;
    }

    getDeviceInforList() {
        return this.deviceDetailInfo;
    }

    setAccountsShortOrgName(accountDetailShortOrgNameInfo, concat) {
        const mappedAccount = accountDetailShortOrgNameInfo.map((account) => { return new accountInfo(account) });
        this.accountDetailShortOrgNameInfo = concat ? this.accountDetailShortOrgNameInfo.concat(mappedAccount) : mappedAccount;
    }

    getAccountInfoList() {
        return this.accountDetailInfo;
    }

    getAccountInfoShortOrgNameList() {
        return this.accountDetailShortOrgNameInfo;
    }

    async getAllDeviceInfo(accountTid, from, limit, options) {
		
		let deviceList = [];
		let hasmore = true;
		
		let lastStartingIndex = 0;
		let currentIndex = from; //0 normally
		
		while(hasmore){
			this.setDeviceStatus(apiStatus.INPROGRESS);
			
			lastStartingIndex = currentIndex + limit;
			
			const deviceResponse = await this.remoteDiagnosticsService.getDevicesList(accountTid, currentIndex, limit, options)
			
			//Remove id from response
			const devicesWithExclusion = differenceBy(
				deviceResponse.data,
				'id'
			);
			
			//We stop if we dont have more.
			currentIndex = lastStartingIndex;
			hasmore = deviceResponse.hasMore;
			
			//make the list 
			let mappedDevice = devicesWithExclusion.map((device) => { return new deviceInfo(device) });
			deviceList = deviceList.concat(mappedDevice);
            //I think this is observable so ill just lazily assign it here.
            this.deviceDetailInfo = deviceList;
		}

		this.setDeviceStatus(apiStatus.COMPLETED);
        return this.getDeviceInforList();
    }

    async searchAccountsShortOrgName(from, limit, shortOrgName, options) {
        try {
            if (from === 0 && this.lastAccountStartShortOrgNameIndex !== limit) {
                this.lastAccountStartShortOrgNameIndex = 0;
                this.hasMoreAccountShortOrgName = true;
            }

            const callingIndex = this.lastAccountStartShortOrgNameIndex;
            if (this.hasMoreAccountShortOrgName) {
                this.setAccountShortOrgNameStatus(apiStatus.INPROGRESS);
                this.lastAccountStartShortOrgNameIndex = callingIndex + limit;
                const accountResponse = await this.remoteDiagnosticsService.searchAccountsShortOrgName(callingIndex, limit, shortOrgName, options)
                this.hasMoreAccountShortOrgName = accountResponse.hasMore;

                const accountsWithExclusion = differenceBy(
                    accountResponse.data,
                    'id'
                );

                this.setAccountsShortOrgName(accountsWithExclusion, from !== undefined && from !== 0);

                if (!this.hasMoreAccountShortOrgName) {
                    this.lastAccountStartShortOrgNameIndex = 0;
                    this.setAccountShortOrgNameStatus(apiStatus.COMPLETED);
                }
                else {
                    if (this.lastAccountStartShortOrgNameIndex > callingIndex) {
                        this.searchAccountsShortOrgName(
                            this.lastAccountStartShortOrgNameIndex,
                            limit,
                            shortOrgName,
                            options
                        );
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
        }

        return this.getAccountInfoShortOrgNameList();
    }

}