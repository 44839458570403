import apiStatus from '../../../shared/apiStatus';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RemoteDiagnosticsStore from '../remoteDiagnosticsStore'

export const getAccounts = createAsyncThunk(
    "accounts/getAccounts",
    async (accountInfo, {rejectWithValue}) => {
        try{
            const remoteDiagnosticsStore = new RemoteDiagnosticsStore();
            const response = await remoteDiagnosticsStore.searchAccountsShortOrgName(0, accountInfo.accountFetchLimit, accountInfo.shortOrgName, accountInfo.options)
            return response;
        } catch (err){
            console.log(err);
            return rejectWithValue(err)
        }
    }
);

const initialState = {
    accounts: [],
    status: apiStatus.NOTSTARTED.toString(),
};

const accountsSlice = createSlice({
    name: "Account",
    initialState,    
    extraReducers: {
        [getAccounts.pending]: (state, action) => {
            state.status = apiStatus.INPROGRESS.toString();
        },
        [getAccounts.fulfilled]: (state, action) => {
            state.status = apiStatus.COMPLETED.toString();
            action.accounts = action.payload
        },
        [getAccounts.rejected]: (state, action) => {
            state.status = apiStatus.WAITING.toString();
        },
    }
})

export default accountsSlice.reducer;